<template>
    <div>
        <div class="row">
            <div class="col-md-3">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            Highlights <small>(aus allen Artikel)</small>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="mr-1 form-group" style="display: inline-block;">
                            <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                <input type="checkbox" class="custom-control-input" id="customSwitchRandomEnable" v-model="form.random_enable">
                                <label class="custom-control-label" for="customSwitchRandomEnable">Aktiv</label>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="" class="control-label form-label">Titel</label>
                            <input type="text" class="form-control form-control-sm" v-model="form.random_title" :class="{'is-invalid': form.errors.has('random_title')}"/>
                            <has-error :form="form" field="random_title"></has-error>
                        </div>

                        <div class="form-group">
                            <label for="" class="control-label form-label">Anzahl der Artikel</label>
                            <input type="text" class="form-control form-control-sm" v-model="form.random_count" :class="{'is-invalid': form.errors.has('random_count')}"/>
                            <has-error :form="form" field="random_count"></has-error>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            Bestseller
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="mr-1 form-group" style="display: inline-block;">
                            <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                <input type="checkbox" class="custom-control-input" id="customSwitchBestsellerEnabled" v-model="form.bestseller_enable">
                                <label class="custom-control-label" for="customSwitchBestsellerEnabled">Aktiv</label>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="" class="control-label form-label">Titel</label>
                            <input type="text" class="form-control form-control-sm" v-model="form.bestseller_title" :class="{'is-invalid': form.errors.has('bestseller_title')}"/>
                            <has-error :form="form" field="bestseller_title"></has-error>
                        </div>

                        <div class="form-group ">
                            <label for="" class="control-label form-label">Anzahl der Artikel</label>
                            <input type="text" class="form-control form-control-sm" v-model="form.bestseller_count" :class="{'is-invalid': form.errors.has('bestseller_count')}"/>
                            <has-error :form="form" field="bestseller_count"></has-error>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            Neu
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="mr-1 form-group" style="display: inline-block;">
                            <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                <input type="checkbox" class="custom-control-input" id="customSwitchNeuEnabled" v-model="form.new_enable">
                                <label class="custom-control-label" for="customSwitchNeuEnabled">Aktiv</label>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="" class="control-label form-label">Titel</label>
                            <input type="text" class="form-control form-control-sm" v-model="form.new_title" :class="{'is-invalid': form.errors.has('new_title')}"/>
                            <has-error :form="form" field="new_title"></has-error>
                        </div>

                        <div class="form-group">
                            <label for="" class="control-label form-label">Anzahl der Artikel</label>
                            <input type="text" class="form-control form-control-sm" v-model="form.new_count" :class="{'is-invalid': form.errors.has('new_count')}"/>
                            <has-error :form="form" field="new_count"></has-error>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            Angebote
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="mr-1 form-group" style="display: inline-block;">
                            <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                <input type="checkbox" class="custom-control-input" id="customSwitchOffersEnabled" v-model="form.offers_enable">
                                <label class="custom-control-label" for="customSwitchOffersEnabled">Aktiv</label>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="" class="control-label form-label">Titel</label>
                            <input type="text" class="form-control form-control-sm" v-model="form.offers_title" :class="{'is-invalid': form.errors.has('offers_title')}"/>
                            <has-error :form="form" field="offers_title"></has-error>
                        </div>

                        <div class="form-group">
                            <label for="" class="control-label form-label">Anzahl der Artikel</label>
                            <input type="text" class="form-control form-control-sm" v-model="form.offers_count" :class="{'is-invalid': form.errors.has('offers_count')}"/>
                            <has-error :form="form" field="offers_count"></has-error>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            Kategorie
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="mr-1 form-group" style="display: inline-block;">
                            <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                <input type="checkbox" class="custom-control-input" id="customSwitchCategoryEnabled" v-model="form.category_enable">
                                <label class="custom-control-label" for="customSwitchCategoryEnabled">Aktiv</label>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="" class="control-label form-label">Titel</label>
                            <input type="text" class="form-control form-control-sm" v-model="form.category_title" :class="{'is-invalid': form.errors.has('category_title')}"/>
                            <has-error :form="form" field="category_title"></has-error>
                        </div>

                        <div class="form-group">
                            <label for="" class="control-label form-label">Anzahl der Artikel</label>
                            <input type="text" class="form-control form-control-sm" v-model="form.category_count" :class="{'is-invalid': form.errors.has('category_count')}"/>
                            <has-error :form="form" field="category_count"></has-error>
                        </div>

                        <div class="form-group">
                            <label for="" class="control-label form-label">Kategorien</label>
                            <select name="category" id="category" class="form-control form-control-sm" v-model="form.category_id" :class="{'is-invalid': form.errors.has('category_id')}">
                                <option v-for="category in company.categories" :key="category.id" :value="category.id">{{ category.name }}</option>
                            </select>
                            <has-error :form="form" field="category_id"></has-error>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            FAQs auf der Startseite anzeigen
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="mr-1 form-group" style="display: inline-block;">
                            <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                <input type="checkbox" class="custom-control-input" id="customSwitchFaqEnabled" v-model="form.faqhome_enable">
                                <label class="custom-control-label" for="customSwitchFaqEnabled">Aktiv</label>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="" class="control-label form-label">Titel</label>
                            <input type="text" class="form-control form-control-sm" v-model="form.faqhome_title" :class="{'is-invalid': form.errors.has('faqhome_title')}"/>
                            <has-error :form="form" field="faqhome_title"></has-error>
                        </div>

                        <div class="form-group">
                            <label for="" class="control-label form-label">Anzahl der Artikel</label>
                            <input type="text" class="form-control form-control-sm" v-model="form.faqhome_count" :class="{'is-invalid': form.errors.has('faqhome_count')}"/>
                            <has-error :form="form" field="faqhome_count"></has-error>
                        </div>

                        <div class="form-group">
                            <label for="" class="control-label form-label">Fragen</label>
                            <select multiple name="faq" id="faq" class="form-control form-control-sm" v-model="form.faqhome_id" :class="{'is-invalid': form.errors.has('faqhome_id')}">
                                <option v-for="faq in company.faqs" :key="faq.id" :value="faq.id">{{ faq.question }} - {{ faq.answer }}</option>
                            </select>
                            <has-error :form="form" field="faqhome_id"></has-error>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="text-right col-md-12">
                <button class="btn btn-primary btn-sm" @click="saveSettings">Speichern</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'companies.details.frontend_settings',

    props: [
        'company',
    ],

    watch:{
        company: function () {
            this.form.fill(this.company);
            if(this.form.faqhome_id != null){
                var array = this.form.faqhome_id.split(';'); 
                this.form.faqhome_id = array; 
            }
            else{
                this.form.faqhome_id = [];
            }
        }
    },

    data() {
        return {
            form: new window.Form({
                random_enable: 1, 
                random_count: '', 
                random_title: '', 
                bestseller_enable: 1, 
                bestseller_count: '', 
                bestseller_title: '', 
                new_enable: 1, 
                new_count: '', 
                new_title: '', 
                offers_enable: 1, 
                offers_count: '', 
                offers_title: '', 
                category_enable: 1, 
                category_count: '', 
                category_title: '', 
                category_id: '', 
                dispatch_tax_rate: '', 
                faqhome_enable: 1, 
                faqhome_count: '', 
                faqhome_title: '', 
                faqhome_id: [], 
            }),
        }
    },

    methods:{
        saveSettings()
        {
            this.form
                .put("/companies/" + this.$route.params.id)
                .then(() => {
                    this.$parent.loadCompany();
                    this.$swal({
                                icon: "success",
                                title: "Änderungen gespeichert!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                });
        },
    },

    created() {
        this.form.fill(this.company);
        if(this.form.faqhome_id != null){
            var array = this.form.faqhome_id.split(';'); 
            this.form.faqhome_id = array; 
        }
        else{
            this.form.faqhome_id = [];
        }
        
    },
}
</script>

<style>

</style>