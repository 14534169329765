<template>
    <div>
        <div class="row">
            <div class="col-md-3">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            Shopseiten
                        </div>

                        <div class="card-tools">
                            <button type="button" class="btn btn-primary btn-sm" @click="createModal" v-if="$auth.check('pages.create')">Neu</button>
                        </div>
                    </div>

                    <div class="card-body">
                        <ul class="list-unstyled">
                            <li style="border: 1px solid #ccc; margin-bottom: .5em; padding: .3em;" v-for="page in company.pages" :key="page.id"><a href="#" @click="fillData(page.id)">{{ page.title }}</a></li>
                        </ul>
                    </div>

                    <div class="card-footer">

                    </div>
                </div>
            </div>
            <div class="col-md-9">
                <span v-if="isFilled == true">
                    <div class="card card-primary card-outline">
                        <div class="card-header">
                            <div class="card-title">
                                Seiteneinstellungen
                            </div>
                            <div class="card-tools">
                                <button class="btn btn-primary btn-sm" @click="saveSettings()" v-if="$auth.check('pages.edit')">Speichern</button>
                            </div>
                        </div>

                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="" class="control-label">Titel</label>
                                        
                                        <input type="text" class="form-control form-control-sm" v-model="form.title" />
                                        
                                    </div>  
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group" v-if="form.it_rechts_kanzlei == 0">
                                        <label for="" class="control-label">Beschreibung (CMS)</label>
                                        <editor v-model="form.content" :init="editorConfig"/>
                                    </div>
                                    <div class="form-group" v-if="form.it_rechts_kanzlei == 1">
                                        <label for="" class="control-label">Beschreibung (Schnittstelle It-Rechtskanzlei)</label>
                                        <textarea class="form-control form-control-sm" style="height: 350px;" v-model="form.content" disabled />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </span>
                <span v-if="isFilled == false">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card card-primary card-outline">
                                <div class="card-header">
                                    <div class="card-title">
                                        <h6> Keine Seite ausgewählt... </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <b-modal id="pageModal" v-bind:title="modalTitle" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk($event, formCreate.id)">
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="" class="control-label">Titel</label>
                            <input type="text" class="form-control form-control-sm" v-model="formCreate.title" :class="{'is-invalid': formCreate.errors.has('title')}"/>
                            <has-error :form="formCreate" field="title"></has-error>
                        </div>  
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="name" class="control-label">Beschreibung</label>
                            <editor
                                        class="form-control"
                                        :class="{'is-invalid': formCreate.errors.has('content')}"
                                        v-model="formCreate.content"
                                        :init="editorConfig"
                                        />
                            <has-error :form="formCreate" field="content"></has-error>
                        </div>
                    </div>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
// import ClassicEditor from '../../../assets/packages/ckeditor5/src/ckeditor';
import "tinymce/tinymce";
// import "./../../../assets/packages/tinymce-bootstrap";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";
import "tinymce/plugins/advlist";
import "tinymce/plugins/autolink";
import "tinymce/plugins/bootstrap";
import "tinymce/plugins/code";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/lists";
import "tinymce/plugins/charmap";
import "tinymce/plugins/print";
import "tinymce/plugins/preview";
import "tinymce/plugins/help";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/table";





export default {
    name: 'companies.details.pages',

    props: [
        'company',
    ],

    data() {
        return {
            url: process.env.VUE_APP_API_URL + 'images/upload',
            editor: Editor,
            // editorConfig: {
            //     a11y_advanced_options: true,
            //     height: 500,
            //     menubar: false,
            //     plugins: 'advlist autolink bootstrap code link image fullscreen lists charmap print preview help table',
            //     toolbar: [
            //         'undo redo | bootstrap | fullscreen',
            //         'cut copy paste | styleselect | alignleft aligncenter alignright alignjustify | bold italic | link image | code | help'
            //     ],
            //     contextmenu: 'link image table spellchecker | bootstrap',
            //     images_upload_handler: uploadImage,
            //     file_picker_types: 'image',
            //     bootstrapConfig: {
            //         url: 'http://localhost:3000/tinymce-bootstrap-plugin',
            //         iconFont: 'fontawesome5',
            //         editorStyleFormats: {
            //             textStyles: true,
            //             blockStyles: true,
            //             containerStyles: true,
            //             responsive: ['xs', 'sm', 'md', 'lg'], // all the responsive options enabled
            //             spacing: ['all', 'x', 'y', 'top', 'right', 'bottom', 'left']
            //         },
            //         elements: { // only the following elements will be added to the toolbar
            //             btn: false,
            //             icon: false,
            //             image: false,
            //             table: true,
            //             template: true,
            //             breadcrumb: false,
            //             pagination: false,
            //             badge: false,
            //             alert: false,
            //             card: false,
            //             snippet: true
            //         },
            //         key: 'tAj3ykawOTzO195azIrI3+pNZJT8Qd4NiKUWc1iwhWMYCiVNl7mKcwWCRo/o89nM+2Q8OIMb8ndAjTt4Iy4SRmj1r+y3Cu3BdQhLqmphy68='
            //     },
            //     formats: { // replace the default Tinymce inline styles with Bootstrap css classes & appropriate tags
            //         alignleft: {selector : 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', classes : 'text-left'},
            //         aligncenter: {selector : 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', classes : 'text-center'},
            //         alignright: {selector : 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', classes : 'text-right'},
            //         alignjustify: {selector : 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', classes : 'text-justify'},
            //         bold: {inline : 'strong'},
            //         italic: {inline : 'em'},
            //         underline: {inline : 'u'},
            //         sup: {inline : 'sup'},
            //         sub: {inline : 'sub'},
            //         strikethrough: {inline : 'del'}
            //     },
            //     style_formats: [
            //         { title: 'Headings', items: [
            //             { title: 'Heading 1', format: 'h1' },
            //             { title: 'Heading 2', format: 'h2' },
            //             { title: 'Heading 3', format: 'h3' },
            //             { title: 'Heading 4', format: 'h4' },
            //             { title: 'Heading 5', format: 'h5' },
            //             { title: 'Heading 6', format: 'h6' }
            //         ]},
            //         { title: 'Blocks', items: [
            //             { title: 'Paragraph', format: 'p' },
            //             { title: 'Blockquote', format: 'blockquote' },
            //             { title: 'Div', block: 'div', wrapper: true }
            //         ]},
            //         { title: 'Containers', items: [
            //             { title: 'Container fluid', block: 'div', classes: 'container-fluid', wrapper: true },
            //             { title: 'Container', block: 'div', classes: 'container', wrapper: true },
            //             { title: 'Section', block: 'section', wrapper: true },
            //             { title: 'Article', block: 'article', wrapper: true }
            //         ]}
            //     ],
            //     style_formats_merge: false, // if true, add TinyMce's default style formats to the user's style_formats,
            //     style_formats_autohide: true // hide the custom formats that don't match the selected element in the editor
            // },
            
            form: new window.Form({
                id: "",
                title: "",
                content: "",
                it_rechts_kanzlei: "", 
                editSlug: false,
            }),
            formCreate: new window.Form({
                id: "",
                title: "",
                content: "",
                it_rechts_kanzlei: false, 
            }),
            modalTitle: '',
            isFilled: false,
            editorConfig: {
                a11y_advanced_options: true,
                height: 500,
                menubar: false,
                plugins: 'advlist autolink bootstrap code link image fullscreen lists charmap print preview help table',
                toolbar: [
                    'undo redo | bootstrap | fullscreen',
                    'cut copy paste | styleselect | alignleft aligncenter alignright alignjustify | bold italic | link image | code | help'
                ],
                contextmenu: 'link image table spellchecker | bootstrap',
                images_upload_handler: this.uploadImage,
                file_picker_types: 'image',
                bootstrapConfig: {
                    url: 'http://localhost:3000/tinymce-bootstrap-plugin',
                    iconFont: 'fontawesome5',
                    editorStyleFormats: {
                        textStyles: true,
                        blockStyles: true,
                        containerStyles: true,
                        responsive: ['xs', 'sm', 'md', 'lg'], // all the responsive options enabled
                        spacing: ['all', 'x', 'y', 'top', 'right', 'bottom', 'left']
                    },
                    elements: { // only the following elements will be added to the toolbar
                        btn: false,
                        icon: false,
                        image: false,
                        table: true,
                        template: true,
                        breadcrumb: false,
                        pagination: false,
                        badge: false,
                        alert: false,
                        card: false,
                        snippet: true
                    },
                    key: 'tAj3ykawOTzO195azIrI3+pNZJT8Qd4NiKUWc1iwhWMYCiVNl7mKcwWCRo/o89nM+2Q8OIMb8ndAjTt4Iy4SRmj1r+y3Cu3BdQhLqmphy68='
                },
                formats: { // replace the default Tinymce inline styles with Bootstrap css classes & appropriate tags
                    alignleft: {selector : 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', classes : 'text-left'},
                    aligncenter: {selector : 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', classes : 'text-center'},
                    alignright: {selector : 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', classes : 'text-right'},
                    alignjustify: {selector : 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', classes : 'text-justify'},
                    bold: {inline : 'strong'},
                    italic: {inline : 'em'},
                    underline: {inline : 'u'},
                    sup: {inline : 'sup'},
                    sub: {inline : 'sub'},
                    strikethrough: {inline : 'del'}
                },
                style_formats: [
                    { title: 'Headings', items: [
                        { title: 'Heading 1', format: 'h1' },
                        { title: 'Heading 2', format: 'h2' },
                        { title: 'Heading 3', format: 'h3' },
                        { title: 'Heading 4', format: 'h4' },
                        { title: 'Heading 5', format: 'h5' },
                        { title: 'Heading 6', format: 'h6' }
                    ]},
                    { title: 'Blocks', items: [
                        { title: 'Paragraph', format: 'p' },
                        { title: 'Blockquote', format: 'blockquote' },
                        { title: 'Div', block: 'div', wrapper: true }
                    ]},
                    { title: 'Containers', items: [
                        { title: 'Container fluid', block: 'div', classes: 'container-fluid', wrapper: true },
                        { title: 'Container', block: 'div', classes: 'container', wrapper: true },
                        { title: 'Section', block: 'section', wrapper: true },
                        { title: 'Article', block: 'article', wrapper: true }
                    ]}
                ],
                style_formats_merge: false, // if true, add TinyMce's default style formats to the user's style_formats,
                style_formats_autohide: true // hide the custom formats that don't match the selected element in the editor
            },
        }
    },

    methods: {
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.handleSubmit()
        },

        handleSubmit() {
            this.createPage();
        },

        createModal() {
            this.isFilled = false; 
            this.editMode = false;
            this.modalTitle = "Neue Seite anlegen";
            this.formCreate.clear();
            this.formCreate.reset();
            this.formCreate.company_id = this.company.id;
            this.$bvModal.show("pageModal");
        },

        createPage() {
            this.formCreate
                .post("/pages")
                .then(() => {
                    this.$bvModal.hide("pageModal");
                    this.$swal({
                        icon: "success",
                        title: "Die Seite wurde erstellt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$parent.loadCompany();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        uploadImage(blobInfo, success, failure, progress){
            var xhr, formData;

            xhr = new XMLHttpRequest();
            xhr.withCredentials = false;
            xhr.open('POST', this.url);
            xhr.setRequestHeader('Accept', 'application/json');
            xhr.setRequestHeader('Authorization', 'Bearer ' + this.$auth.token());

            xhr.upload.onprogress = function (e) {
                progress(e.loaded / e.total * 100);
            };

            xhr.onload = function() {
                var json;

                if (xhr.status === 403) {
                failure('HTTP Error: ' + xhr.status, { remove: true });
                return;
                }

                if (xhr.status < 200 || xhr.status >= 300) {
                failure('HTTP Error: ' + xhr.status);
                return;
                }

                json = JSON.parse(xhr.responseText);

                if (!json || typeof json.urls.default != 'string') {
                failure('Invalid JSON: ' + xhr.responseText);
                return;
                }

                success(json.urls.default);
            };

            xhr.onerror = function () {
                failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
            };

            formData = new FormData();
            formData.append('upload', blobInfo.blob(), blobInfo.filename());

            xhr.send(formData);
        },

        saveSettings()
        {
            this.form
                .put("/pages/" + this.form.id)
                .then(() => {
                    this.$parent.loadCompany();
                    this.$swal({
                        icon: "success",
                                title: "Änderungen gespeichert!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                    });
                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                });
        },
        
        fillData(id)
        {
            this.axios
                .get("/pages/" + id)
                .then((response) => {
                    this.isFilled = true; 
                    this.form.reset();
                    this.form.fill(response.data.data);
                    this.form.editSlug = false;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },       
    },
}
</script>

<style>

</style>