<template>
    <div>
        <div class="card card-primary card-outline">
            <div class="card-header">
                <div class="card-title">
                    Icons
                </div>
                <div class="card-tools">
                    <button class="btn btn-primary btn-sm" @click="createIconModal()" v-if="$auth.check('icons.create')">
                        <i class="fas fa-plus"></i> Hinzufügen
                    </button>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-2 pb-2 pt-2 text-center" v-for="icon in icons.data" :key="icon.name">
                        <div v-html="icon.vector" style="color: black;" class="icon"></div>
                        <b>{{ icon.name }}</b>
                        <div class="row">
                            <div class="col">
                                <button v-if="$auth.check('icons.destroy') && icon.default !== 1" class="btn btn-danger btn-xs" v-b-popover.hover.top="'Löschen'" @click="destroyIcon(icon.id)" >
                                    <i class="fas fa-trash"></i>
                                </button>
                                <button class="btn btn-warning btn-xs ml-1" v-b-popover.hover.bottom="'Als Standard Icon setzen'" v-if="icon.default == 0" @click="setDefaultIcon(icon.id)">
                                    <i class="fas fa-lock"></i>
                                </button>
                                <div v-if="icon.default == 1">
                                    <b>* Standard *</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="createIcon" title="Icon hochladen" size="md" @ok="createIcon($event)">
            <form ref="form" @submit.stop.prevent="" enctype="multipart/form-data">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group form-group-sm">
                            <label>Name*</label>
                            <input type="text" class="form-control form-control-sm" v-model="form.name"  :class="{'is-invalid': form.errors.has('name')}">
                            <has-error :form="form" field="name"></has-error>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group form-group-sm">
                            <label>Icon-Datei (.svg)*</label>
                            <b-form-group label="" label-cols-sm="0" label-size="sm">
                                <b-form-file id="file-small" size="sm" v-model="form.file" :class="{'is-invalid': form.errors.has('file')}"></b-form-file>
                                <has-error :form="form" field="file"></has-error>
                            </b-form-group>
                        </div>
                    </div>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: 'companies.details.icons',
    props: ['company'],
    data() {
        return {
            icons: [],
            search: '',
            page: 1,
            params: {
                perPage: 30,
            },
            form: new window.Form({
                company_id: '',
                id: '',
                name: '',
                file: '',
            })
        }
    },
    methods: {
        createIconModal() {
            this.form.reset();
            this.form.company_id = this.company.id;
            this.$bvModal.show('createIcon');
        },
        createIcon(event) {
            event.preventDefault();
            this.form
                .post("/icons")
                .then(() => {
                    this.$bvModal.hide('createIcon');
                    this.form.reset();
                    this.getIcons();
                })
                .catch(() => {
                });
        },
        getIcons(page = undefined) {
            if(page == undefined)
            {
                page = 1;
            }
            this.axios
                .get("/icons", {
                    params: {
                        company_id: this.company.id,
                        search: this.search,
                        page: page,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.icons = response.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Beim laden der Icons ist etwas schief gelaufen'
                    })
                });
        },
        destroyIcon(id) {
            this.axios
                .delete('/icons/'+id)
                .then(() => {
                    this.getIcons();
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    })
                });
        },
        setDefaultIcon(id) {
            this.axios
                .post('icon/set-default/' + id)
                .then(() => {
                    this.getIcons();
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    })
                })
        }
    },
    mounted() {
        this.getIcons();
    }
}
</script>

<style>
.icon svg{
    width: 64px;
    height: 64px;
}
</style>
