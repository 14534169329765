<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-6">
                        <div class="card card-primary card-outline" v-if="$auth.check('dispatches.display')">
                            <div class="card-header">
                                <div class="card-title">
                                    Versandarten
                                </div>

                                <div class="card-tools">
                                    <nav class="py-0 bg-white navbar navbar-expand navbar-light justify-content-end">
                                        <ul class="navbar-nav">  
                                            <li class="nav-item">
                                                <button type="button" class="btn btn-sm btn-primary" @click="createDispModal" v-if="$auth.check('dispatches.create')">
                                                    <i class="fas fa-plus-circle"></i> Neue Versandart
                                                </button>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>

                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="table table-hover table-sm">
                                        <thead>
                                            <th>Name</th>
                                            <th>Beschreibung</th>
                                            <!-- <th>Optionen</th> -->
                                            <th>Versandkosten</th>
                                            <th style="width: 140px;">Versankostenfrei ab</th>
                                            <th style="width: 140px;">Aktionen</th>                 
                                        </thead>
                                        <tbody>
                                            <tr v-for="dispatch in dispatches" :key="dispatch.id" :class="dispatch.active == 0 ? 'table-danger' : null">
                                                <td>{{ dispatch.name }}</td>
                                                <td>{{ dispatch.description }}</td>
                                                <!-- <td> 
                                                    <span v-if="dispatch.active == 1"><i class="fas fa-check"></i></span>
                                                    <span v-if="dispatch.position != null">{{ dispatch.position }}</span>
                                                </td> -->
                                                <td><span v-if="dispatch.amount != null">{{ dispatch.amount }} &euro;</span></td>
                                                <td><span v-if="dispatch.shippingfree != null">{{ dispatch.shippingfree }} &euro;</span></td>
                                                <td>
                                                    <!-- <router-link class="mr-1 btn btn-success btn-xs" :to="{name: 'distributors.show', params: {id: distributor.id}}" v-if="$auth.check('distributors.show') && !$auth.check('distributors.edit')"><i class="fas fa-fw fa-eye"></i></router-link> -->
                                                    <b-button size="xs" @click="editDispModal(dispatch)" class="mr-1" variant="warning" v-if="$auth.check('dispatches.edit')"><i class="fas fa-edit"></i></b-button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="card card-primary card-outline" v-if="$auth.check('payments.display')">
                            <div class="card-header">
                                <div class="card-title">
                                    Zahlungsarten
                                </div>
                            </div>

                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="table table-hover table-sm">
                                        <thead>
                                            <th>Name</th>
                                            <th>Beschreibung</th>
                                            <th>Aktiv</th>
                                            <!-- <th>Position</th> -->
                                            <th style="width: 140px;">Aktionen</th>                 
                                        </thead>
                                        <tbody>
                                            <tr v-for="payment in company.payments" :key="payment.id">
                                                <td>{{ payment.description }}</td>
                                                <td>{{ payment.additionaldescription }}</td>
                                                <td> 
                                                    <span v-if="payment.active == 1"><i class="fas fa-check"></i></span>
                                                    <span v-if="payment.active == 0"><i class="fas fa-times"></i></span>
                                                </td>
                                                <!-- <td>
                                                    <span v-if="payment.position != null">{{ payment.position }}</span>
                                                </td> -->
                                                <td>
                                                    <b-button size="xs" @click="editPaymModal(payment)" class="mr-1" variant="warning" v-if="$auth.check('payments.edit')"><i class="fas fa-edit"></i></b-button>
                                                    <!-- <b-button size="xs" @click="deleteDistributor(distributor.id)" variant="danger" v-if="$auth.check('distributors.destroy')"><i class="fas fa-fw fa-trash"></i></b-button> -->
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <b-modal id="dispatchModal" v-bind:title="modalTitle" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOkDisp($event, formDisp.id)">
            <form ref="form" @submit.stop.prevent="handleSubmitDisp">
                <div class="row">
                    <div class="col-md-12">
                        <div class="mr-1 form-group" style="display: inline-block;">
                            <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                <input type="checkbox" class="custom-control-input" id="customSwitchActive" v-model="formDisp.active" :class="{'is-invalid': formDisp.errors.has('active')}">
                                <label class="custom-control-label" for="customSwitchActive">Aktiv</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="" class="control-label col-sm-3 col-form-label col-form-label-sm">Name</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" v-model="formDisp.name" :class="{'is-invalid': formDisp.errors.has('name')}" />
                                <has-error :form="formDisp" field="name"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="" class="control-label col-sm-3 col-form-label col-form-label-sm">
                                Beschreibung*
                                <i class="fas fa-question-circle" id="tooltip-dispatch-description"></i>
                                <b-tooltip target="tooltip-dispatch-description" triggers="hover">
                                    Dies wird als Fragezeichen hinter der Versandart angezeigt
                                </b-tooltip>  
                            </label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" v-model="formDisp.description" :class="{'is-invalid': formDisp.errors.has('description')}" />
                                <has-error :form="formDisp" field="description"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="" class="control-label col-sm-3 col-form-label col-form-label-sm">Kommentar    
                                <i class="fas fa-question-circle" id="tooltip-dispatch-comment"></i>
                                <b-tooltip target="tooltip-dispatch-comment" triggers="hover">
                                    Dies wird als Text unter der Versandart angezeigt
                                </b-tooltip>
                            </label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" v-model="formDisp.comment" :class="{'is-invalid': formDisp.errors.has('comment')}" />
                                <has-error :form="formDisp" field="comment"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="" class="control-label col-sm-3 col-form-label col-form-label-sm">Position*</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" v-model="formDisp.position" :class="{'is-invalid': formDisp.errors.has('position')}" />
                                <has-error :form="formDisp" field="position"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="" class="control-label col-sm-3 col-form-label col-form-label-sm">eingeschlossenes PLZ-Gebiet</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" v-model="formDisp.include_zipcode" :class="{'is-invalid': formDisp.errors.has('include_zipcode')}" />
                                <has-error :form="formDisp" field="included_zipcode"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="" class="control-label col-sm-3 col-form-label col-form-label-sm">ausgeschlossenes PLZ-Gebiet</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" v-model="formDisp.exclude_zipcode" :class="{'is-invalid': formDisp.errors.has('exclude_zipcode')}" />
                                <has-error :form="formDisp" field="exclude_zipcode"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="" class="control-label col-sm-3 col-form-label col-form-label-sm">Zugewiesene Zahlungsarten*</label>
                            <div class="col-sm-9">
                                <Select2 v-model="formDisp.selectedPayments" :options="company.payments" :settings="{ multiple: true }" aria-placeholder="Zahlungsart wählen" :class="{'is-invalid': formDisp.errors.has('selectedPayments')}"/>
                                <has-error :form="formDisp" field="selectedPayments"></has-error>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="" class="control-label col-sm-4 col-form-label col-form-label-sm">Versandkostenfrei ab</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control form-control-sm" v-model="formDisp.shippingfree" :class="{'is-invalid': formDisp.errors.has('shippingfree')}" />
                                <has-error :form="formDisp" field="shippingfree"></has-error>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="" class="control-label col-sm-4 col-form-label col-form-label-sm">Versandkosten</label>
                            <div class="col-sm-8">
                                <input type="text" class="form-control form-control-sm" v-model="formDisp.amount" :class="{'is-invalid': formDisp.errors.has('amount')}" />
                                <has-error :form="formDisp" field="amount"></has-error>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="mr-1 form-group" style="display: inline-block;">
                                    <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                        <input type="checkbox" class="custom-control-input" id="customSwitchSurcharge" v-model="formDisp.surcharge" :class="{'is-invalid': formDisp.errors.has('surcharge')}">
                                        <label class="custom-control-label" for="customSwitchSurcharge">Zuschläge</label>
                                        <i class="fas fa-question-circle" id="tooltip-dispatch-surcharge"></i>
                                        <b-tooltip target="tooltip-dispatch-surcharge" triggers="hover">
                                            Dürfen Zuschläge berechnet werden? (Kühl, Bruch, Prio, Express etc...)
                                        </b-tooltip>
                                        <has-error :form="formDisp" field="surcharge"></has-error>
                                    </div>
                                </div><br/>
                                <div class="mr-1 form-group" style="display: inline-block;">
                                    <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                        <input type="checkbox" class="custom-control-input" id="customSwitchDispExtendedService" v-model="formDisp.extended_service" :class="{'is-invalid': formDisp.errors.has('extended_service')}">
                                        <label class="custom-control-label" for="customSwitchDispExtendedService">
                                            Service-Leistungen anbieten
                                            <i class="fas fa-question-circle" id="tooltip-dispatch-extended_service"></i>
                                            <b-tooltip target="tooltip-dispatch-extended_service" triggers="hover">
                                                Beinhaltet: Wunschtermin, Lieferankündigung
                                            </b-tooltip>
                                        </label>
                                    </div>
                                    <has-error :form="formDisp" field="extended_service"></has-error>
                                </div><br/>
                                <div class="mr-1 form-group" style="display: inline-block;">
                                    <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                        <input type="checkbox" class="custom-control-input" id="customSwitchCool" v-model="formDisp.cool" :class="{'is-invalid': formDisp.errors.has('cool')}">
                                        <label class="custom-control-label" for="customSwitchCool">Kühlzuschlag</label>
                                        <has-error :form="formDisp" field="cool"></has-error>
                                    </div>
                                </div><br/>
                                <div class="mr-1 form-group" style="display: inline-block;">
                                    <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                        <input type="checkbox" class="custom-control-input" id="customSwitchPrio" v-model="formDisp.prio_external" :class="{'is-invalid': formDisp.errors.has('prio_external')}">
                                        <label class="custom-control-label" for="customSwitchPrio">Prio</label>
                                        <has-error :form="formDisp" field="prio"></has-error>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="mr-1 form-group" style="display: inline-block;">
                                    <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                        <input type="checkbox" class="custom-control-input" id="customSwitchDispExpress" v-model="formDisp.express" :class="{'is-invalid': formDisp.errors.has('express')}">
                                        <label class="custom-control-label" for="customSwitchDispExpress">Express</label>
                                        <has-error :form="formDisp" field="express"></has-error>
                                    </div>
                                </div><br/>
                                <div class="mr-1 form-group" style="display: inline-block;">
                                    <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                        <input type="checkbox" class="custom-control-input" id="customSwitchPickup" v-model="formDisp.pickup" :class="{'is-invalid': formDisp.errors.has('pickup')}">
                                        <label class="custom-control-label" for="customSwitchPickup">Selbstabholung</label>
                                        <has-error :form="formDisp" field="Pickup"></has-error>
                                    </div>
                                </div><br/>
                                <div class="mr-1 form-group" style="display: inline-block;">
                                    <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                        <input type="checkbox" class="custom-control-input" id="customSwitchDispAbo" v-model="formDisp.abo" :class="{'is-invalid': formDisp.errors.has('abo')}">
                                        <label class="custom-control-label" for="customSwitchDispAbo">Abo</label>
                                        <has-error :form="formDisp" field="abo"></has-error>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group row" v-if="formDisp.cool == 1">
                                    <label for="" class="control-label col-sm-4 col-form-label col-form-label-sm">Kühlpauschale (Preis):</label>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control form-control-sm" v-model="formDisp.cool_value" :class="{'is-invalid': formDisp.errors.has('cool_value')}" />
                                        <has-error :form="formDisp" field="cool_value"></has-error>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-12">
                        
                    </div> -->
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <h3>Preise</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <table class="table table-sm table-bordered">
                            <thead>
                                <th style="width: 50px; text-align: center;">#</th>
                                <th>WK-Von</th>
                                <th>WK-Bis</th>
                                <th>Preis</th>
                            </thead>
                            <tbody>
                                <tr v-for="(price, index) in formDisp.prices" :key="price.id">
                                    <td style="width: 50px; text-align: center;">
                                        <button v-if="(index+1) == formDisp.prices.length && index != 0" class="btn btn-xs btn-danger" @click="deletePrice(index)"><i class="fas fa-trash"></i></button>
                                    </td>
                                    <td>
                                        <input type="number" step="1" class="form-control form-control-sm" v-model="formDisp.prices[index].from" disabled/>
                                    </td>
                                    <td>
                                        <input type="number" step="1" class="form-control form-control-sm" v-model="formDisp.prices[index].to" placeholder="∞" v-on:blur="printConsole($event, index)" :disabled="(index+1) < formDisp.prices.length"/>
                                    </td>
                                    <td>
                                        <input type="number" step="0.01" class="form-control form-control-sm" v-model="formDisp.prices[index].price" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </form>
        </b-modal>

        <b-modal id="paymentModal" v-bind:title="modalTitle" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOkPaym($event, formPaym.id)">
            <form ref="form" @submit.stop.prevent="handleSubmitPaym">
                <div class="mr-1 form-group" style="display: inline-block;">
                    <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                        <input type="checkbox" class="custom-control-input" id="customSwitchPaymActive" v-model="formPaym.active">
                        <label class="custom-control-label" for="customSwitchPaymActive">Aktiv</label>
                    </div>
                </div>
                <!-- <div class="form-group row">
                    <label for="" class="control-label col-sm-2 col-form-label col-form-label-sm">Position</label>
                    <div class="col-sm-2">
                        <input type="text" class="form-control form-control-sm" v-model="formPaym.position" />
                    </div>
                </div> -->
                <!-- <div class="form-group row">
                    <label for="" class="control-label col-sm-2 col-form-label col-form-label-sm">Systemname</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control form-control-sm" v-model="formPaym.name" />
                    </div>
                </div> -->
                <div class="form-group row">
                    <label for="" class="control-label col-sm-2 col-form-label col-form-label-sm">Name</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control form-control-sm" v-model="formPaym.description" />
                    </div>
                </div>
                <div class="form-group row">
                    <label for="" class="control-label col-sm-2 col-form-label col-form-label-sm">Beschreibung</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control form-control-sm" v-model="formPaym.additionaldescription" />
                    </div>
                </div>
            </form>
        </b-modal>

        
    </div>
</template>

<script>
export default {
    name: 'companies.details.dispatchespayments',

    props: [
        'company',
    ],

    data() {
        return {
            dispatches: [],
            formDisp: new window.Form({
                id: '', 
                company_id: '', 
                name : '', 
                type: false, 
                description: '', 
                comment: '', 
                active: true, 
                position: '', 
                include_zipcode: '', 
                exclude_zipcode: '', 
                cool: false, 
                cool_value: '', 
                amount: '', 
                shippingfree: '', 
                surcharge: false, 
                extended_service: false, 
                express: false, 
                pickup: false, 
                abo: false,  
                selectedPayments: [],
                prio_external: false,
                prices: [],
            }),

            formPaym: new window.Form({
                id: '',
                company_id: '', 
                name : '', 
                description: '', 
                additionaldescription: '', 
                position: '', 
                active: true, 
                abo: false,  
            }),
            editDispMode: false,
            editPaymMode: false,
            modalTitle: '',
        }
    },

    methods:{
        deletePrice(index){
            this.$delete(this.formDisp.prices, index);
            this.formDisp.prices[index-1].to = null;
        },

        printConsole(event, index){
            //Prüfe ob überhaupt ein Wert eingegeben wurde

            if(event.target.value != ''){
                //Prüfe zunächst ob ein Folgeelement vorliegt
                // Sollte es bereits einen Nachfolger geben, muss nichts neues erstellt werden, aber ggf. From To angepasst werden.
                if(this.formDisp.prices.length > (index+1)){
                    //Es gibt schon ein Nachfolger
                    //Todo: Prüfe From and To
                }
                else{
                    let previousPrice;
                    //Es gibt noch keinen Nachfolger. Also legen wir einen an.
                    if(index < this.formDisp.prices.length){
                        previousPrice = this.formDisp.prices[index];
                    }
                    else{
                        previousPrice = this.formDisp.prices[index-1];
                    }
                    
                    //Prüfe nun das die neue Anzahl über der alten liegt
                    if(event.target.value > previousPrice.from){
                        const addPrice = {
                        from: Number(event.target.value) + 0.01,
                        to: null,
                        price: previousPrice.price,
                        }
                        this.formDisp.prices.push(addPrice);
                    }
                    else{
                        this.formDisp.prices[index].to = null;
                    }
                }
            }            
        },

        getDispatches() {
            this.axios
                .get("/dispatches", {
                    params: {
                        company_id: this.company.id
                    }
                })
                .then((response) => {
                    this.dispatches = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Beim laden der Versandarten ist etwas schief gelaufen'
                    })
                })
        },
        handleOkDisp(bvModalEvt, id) {
            bvModalEvt.preventDefault()
            this.handleSubmitDisp(id)
        },

        handleSubmitDisp(id) {
            if(this.editDispMode == true)
            {
                //Edit 
                this.editDispatch(id);
            }
            else
            {
                //Create 
                this.createDispatch();
            }
        },

        createDispModal() {
            this.editDispMode = false;
            this.formDisp.selectedPayments = [];
            this.modalTitle = "Neue Versandart anlegen";
            this.formDisp.clear();
            this.formDisp.reset();
            this.formDisp.company_id = this.company.id;
            this.formDisp.prices = [
                {
                    from: 0,
                    to: null,
                    price: 0.00
                }
            ];
            this.$bvModal.show("dispatchModal");
        },

        editDispModal(dispatch) {
            this.editDispMode = true;
            this.formDisp.clear();
            this.formDisp.reset();
            this.formDisp.fill(dispatch);
            this.formDisp.selectedPayments = [];
            const index = this.company.dispatches.findIndex(item => item.id == dispatch.id);
            this.company.dispatches[index].selectedPayments.forEach(function(payment){
                this.formDisp.selectedPayments.push(payment.id)
            },this)
            this.modalTitle = "Versandart editieren";
            this.$bvModal.show("dispatchModal");
        },

        createDispatch() {
            this.formDisp
                .post("/dispatches")
                .then(() => {
                    this.$bvModal.hide("dispatchModal");
                    this.$swal({
                        icon: "success",
                        title: "Versandart wurde erstellt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.getDispatches();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        editDispatch(id){
            this.formDisp
                .put("/dispatches/" + id)
                .then(() => {
                    this.$bvModal.hide("dispatchModal");
                    this.$swal({
                        icon: "success",
                        title: "Versandart wurde editiert",
                    });
                    this.getDispatches();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        handleOkPaym(bvModalEvt, id) {
            bvModalEvt.preventDefault()
            this.handleSubmitPaym(id)
        },

        handleSubmitPaym(id) {
            if(this.editPaymMode == true)
            {
                //Edit 
                this.editPayment(id);
            }
            else
            {
                //Create 
                this.createPayment();
            }
        },

        createPaymModal() {
            this.editPaymMode = false;
            this.modalTitle = "Neue Zahlungsart anlegen";
            this.formPaym.clear();
            this.formPaym.reset();
            this.formPaym.company_id = this.company.id;
            this.$bvModal.show("paymentModal");
        },

        editPaymModal(payment) {
            this.editPaymMode = true;
            this.modalTitle = "Zahlungsart editieren";
            this.formPaym.clear();
            this.formPaym.reset();
            this.formPaym.fill(payment);
            this.$bvModal.show("paymentModal");

        },

        createPayment() {
            this.formPaym
                .post("/payments")
                .then(() => {
                    this.$bvModal.hide("paymentModal");
                    this.$swal({
                        icon: "success",
                        title: "Zahlungsart wurde erstellt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$parent.loadCompany();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        editPayment(id){
            this.formPaym
                .put("/payments/" + id)
                .then(() => {
                    this.$bvModal.hide("paymentModal");
                    this.$swal({
                        icon: "success",
                        title: "Zahlungsart wurde editiert",
                    });
                    this.$parent.loadCompany();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        // saveSettings()
        // {
        //     this.formDisp
        //         .put("/companies/" + this.$route.params.id)
        //         .then(() => {
        //             this.$parent.loadCompany();
        //             this.$swal({
        //                         icon: "success",
        //                         title: "Änderungen gespeichert!",
        //                         toast: true,
        //                         position: 'top-end',
        //                         showConfirmButton: false,
        //                         timer: 2000,
        //                         timerProgressBar: true,
        //                         });
                    
        //         })
        //         .catch(() => {
        //             this.$swal({
        //                 icon: "error",
        //                 title: "Oops...",
        //                 text: "Da ist etwas schief gelaufennnnn",
        //             });

        //         });
        // },
    },

    created() {
        this.formDisp.fill(this.company);
        this.formPaym.fill(this.company);
        this.getDispatches();
    },
}
</script>

<style>

</style>