<template>
    <div>
        <div class="row">
            <div class="col-md-3">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            Menüs
                        </div>

                        <div class="card-tools">
                            <button type="button" class="btn btn-primary btn-sm" @click="createModalMenu" v-if="$auth.check('menus.create')">Neu</button>
                        </div>
                    </div>

                    <div class="card-body">
                        <ul class="list-unstyled">
                            <li style="border: 1px solid #ccc; margin-bottom: .5em; padding: .3em;" v-for="menu in company.menus" :key="menu.id"><a href="#" @click="fillData(menu)">{{ menu.description }}</a></li>
                        </ul>
                    </div>

                    <div class="card-footer">

                    </div>
                </div>
            </div>

            <div class="col-md-9">
                <span v-if="isFilled == true">
                    <div class="card card-primary card-outline">
                        <div class="card-header">
                            <div class="card-title">
                                Menüverwaltung
                            </div>
                            <div class="card-tools">
                                <button class="btn btn-primary btn-sm" @click="saveMenu()" v-if="$auth.check('menus.edit')">Speichern</button>
                            </div>
                        </div>

                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="" class="control-label">Name im System*</label>
                                        <input type="text" class="form-control form-control-sm" v-model="form.name" />
                                    </div>  
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="description" class="control-label">Name in der Front*</label>
                                        <input type="text" class="form-control form-control-sm" v-model="form.description" />
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="form.name != ''">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <br/>
                                        <b-button v-b-toggle="'menuItem-' + form.id" variant="primary">Code-Schnipsel:</b-button>
                                        <b-collapse :id="'menuItem-' + form.id" class="mt-2">
                                            <p v-html="generateCode(form.name)"></p>
                                        </b-collapse>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card card-primary card-outline" v-if="$auth.check('menuitems.display')">
                        <div class="card-header">
                            <div class="card-title">
                                Menü-Seiten
                            </div>
                            <div class="card-tools">
                                <button type="button" class="btn btn-primary btn-sm" @click="createModal" v-if="$auth.check('menuitems.create')">Neu</button>
                            </div>
                        </div>

                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-hover table-sm">
                                    <thead>
                                        <th></th>
                                        <th>Name</th>
                                        <th>Verknüpfung</th>
                                        <th style="width: 140px;">Aktionen</th>                 
                                    </thead>
                                    <draggable tag="tbody" :list="menu_items" handle=".draggable" @change="saveOrder()">
                                        <tr v-for="item in menu_items" :key="item.id" :class="item.active == 0 ? 'table-danger' : ''">
                                            <td class="draggable"><i class="fas fa-bars"></i></td>
                                            <td>{{ item.name }}</td>
                                            <td>
                                                <span v-if="item.mode == 'page'">Seite</span>
                                                <span v-if="item.mode == 'internal'">{{ item.internal_link }}</span>
                                                <span v-if="item.mode == 'external'">{{ item.external_link }}</span>
                                            </td>
                                            <td>
                                                <b-button size="xs" @click="editModal(item)" class="mr-1" variant="warning" v-if="$auth.check('menuitems.edit')"><i class="fas fa-edit"></i></b-button>
                                            </td>
                                        </tr>
                                    </draggable>
                                </table>
                            </div>
                        </div>
                    </div>
                </span>
                <span v-if="isFilled == false">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card card-primary card-outline">
                                <div class="card-header">
                                    <div class="card-title">
                                        <h6> Bitte Menü wählen... </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <b-modal id="menuItemModal" v-bind:title="modalTitle" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk($event, formItems.id)">
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="" class="control-label col-sm-3 col-form-label col-form-label-sm">Name*</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" v-model="formItems.name" :class="{'is-invalid': formItems.errors.has('name')}"/>
                            </div>
                            <has-error :form="formItems" field="name"></has-error>
                        </div>

                        <!-- <div class="form-group row">
                            <label for="" class="control-label col-sm-3 col-form-label col-form-label-sm">Position*</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" v-model="formItems.position" :class="{'is-invalid': formItems.errors.has('position')}"/>
                            </div>
                            <has-error :form="formItems" field="position"></has-error>
                        </div> -->

                        <div class="mr-1 form-group" style="display: inline-block;">
                            <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                <input type="checkbox" class="custom-control-input" id="customSwitchActiveMenuItem" v-model="formItems.active">
                                <label class="custom-control-label" for="customSwitchActiveMenuItem">Aktiv?</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="mode" class="control-label col-sm-3 col-form-label col-form-label-sm">Modus*</label>
                            <div class="col-sm-9">
                                <select name="mode" id="mode" class="form-control form-control-sm" v-model="formItems.mode" :class="{'is-invalid': formItems.errors.has('mode')}">
                                    <option value="page">Seite</option>
                                    <option value="internal">Interner Link</option>
                                    <option value="external">Externer Link</option>
                                </select>
                                <has-error :form="formItems" field="mode"></has-error>
                            </div>
                        </div>

                        <div class="form-group row" v-if="formItems.mode == 'page'">
                            <label for="" class="control-label col-sm-3 col-form-label col-form-label-sm">Seite*</label>
                            <div class="col-sm-9">
                                <select name="page" id="page" class="form-control form-control-sm" v-model="formItems.page_id" :class="{'is-invalid': formItems.errors.has('page_id')}">
                                    <option v-for="page in company.pages" :key="page.id" :value="page.id">{{ page.title }}</option>
                                </select>
                                <has-error :form="formItems" field="page_id"></has-error>
                            </div>
                        </div>

                        <div class="form-group row" v-if="formItems.mode == 'internal'">
                            <label for="" class="control-label col-sm-3 col-form-label col-form-label-sm">Interner Link*</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" v-model="formItems.internal_link" :class="{'is-invalid': formItems.errors.has('internal_link')}"/>
                            </div>
                            <has-error :form="formItems" field="internal_link"></has-error>
                        </div>

                        <div class="form-group row" v-if="formItems.mode == 'external'">
                            <label for="" class="control-label col-sm-3 col-form-label col-form-label-sm">Externer Link*</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" v-model="formItems.external_link" :class="{'is-invalid': formItems.errors.has('external_link')}"/>
                            </div>
                            <has-error :form="formItems" field="external_link"></has-error>
                        </div>
                    </div>
                </div>
            </form>
        </b-modal>
        <b-modal id="menuModal" v-bind:title="modalTitle" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOkMenu($event, form.id)">
            <form ref="form" @submit.stop.prevent="handleSubmitMenu">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="" class="control-label">Name*</label>
                            <input type="text" class="form-control form-control-sm" v-model="form.name" :class="{'is-invalid': form.errors.has('name')}"/>
                            <has-error :form="form" field="name"></has-error>
                        </div>  
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="description" class="control-label">Beschreibung*</label>
                            <input type="text" class="form-control form-control-sm" v-model="form.description" :class="{'is-invalid': form.errors.has('description')}"/>
                            <has-error :form="form" field="description"></has-error>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="" class="control-label">Position*</label>
                            <input type="text" class="form-control form-control-sm" v-model="form.position" :class="{'is-invalid': form.errors.has('position')}"/>
                            <has-error :form="form" field="position"></has-error>
                        </div>  
                    </div>
                </div>
            </form>
        </b-modal>
        <b-modal id="codeModal" title="Code-Schnipsel" ok-only ok-variant="danger" ok-title="Ok" size="xl" @ok="Ok()">
                <p>
                    <b>Bitte füge folgenden Code in den Quelltext ein: </b>
                    <br/><br/>
                    {{ generateCode(form.name) }}
                </p>
        </b-modal>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
export default {
    name: 'companies.details.menus',
    components: {draggable},
    props: [
        'company',
    ],

    data() {
        return {
            form: new window.Form({
                id: "",
                name: '', 
                position: '', 
                active: true, 
                description: '', 
                company_id: '', 
            }),
            formItems: new window.Form({
                id: "",
                menu_id: "",
                name: '', 
                position: '', 
                page_id: '', 
                internal_link: '', 
                external_link: '', 
                active: true, 
                mode: '', 
            }),
            editMode: false,
            editModeMenu: false, 
            modalTitle: '',
            isFilled: [], 
            menu_items: [], 
            code: '@foreach($%code%->items as $item)<br>&nbsp;&nbsp;&nbsp;&nbsp;&lt;li><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;@if($item->external_link != null)<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;a href="{{ $item->getUrl() }}" target="_blank"><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ $item->name }}<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/a><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;@else<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;a href="{{ $item->getUrl() }}"><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ $item->name }}<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/a><br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;@endif<br>&nbsp;&nbsp;&nbsp;&nbsp;&lt;/li><br>@endforeach', 
        }
    },

    methods: {
        saveOrder() {
            this.axios
                .post("menuitem/changeOrder", {
                    menuItems: this.menu_items
                })
                .then(() => {
                    this.loadMenuItems(this.form.id)
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Beim ändern der reihenfolge ist etwas schief gelaufen'
                    })
                })
        },
        generateCode(code) {
            return this.code.replace('%code%', code); 

        },

        handleOk(bvModalEvt, id) {
            bvModalEvt.preventDefault()
            this.handleSubmit(id)
        },

        handleSubmit(id) {
            if(this.editMode == true)
            {
                //Edit 
                this.editItem(id);
            }
            else
            {
                //Create 
                this.createItem();
            }
        },

        createModal() {
            this.isFilled = true; 
            this.editMode = false;
            this.modalTitle = "Neuen Menü-Punkt anlegen";
            this.formItems.clear();
            this.formItems.reset();
            this.formItems.company_id = this.company.id;
            this.formItems.menu_id = this.form.id;
            this.$bvModal.show("menuItemModal");
        },

        editModal(item) {
            this.editMode = true;
            this.formItems.clear();
            this.formItems.reset();
            this.formItems.fill(item);
            this.modalTitle = "Menü editieren";
            this.$bvModal.show("menuItemModal");
        },

        createItem() {
            this.formItems
                .post("/menuitems")
                .then(() => {
                    this.$bvModal.hide("menuItemModal");
                    this.$swal({
                        icon: "success",
                        title: "Menü-Punkt wurde erstellt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.loadMenuItems(this.formItems.menu_id);
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        editItem(id){
            this.formItems
                .put("/menuitems/" + id)
                .then((response) => {
                    this.$bvModal.hide("menuItemModal");
                    this.$swal({
                        icon: "success",
                        title: "Menü-Punkt wurde editiert",
                    });
                    this.loadMenuItems(response.data.data.menu_id); 
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        loadMenuItems(menu_id){
            this.axios
                .get("/menuitems?menu_id=" + menu_id, {
                    params: {
                        sort_field: 'position',
                        sort_direction: 'asc'
                    }
                })
                .then((response) => {
                    this.menu_items = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        }, 
        Ok() {
            this.$bvModal.hide("codeModal");
        },

        handleOkMenu(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.handleSubmitMenu()
        },

        handleSubmitMenu() {
            this.createMenu();
        },

        createModalMenu() {
            this.isFilled = false; 
            this.editModeMenu = false;
            this.modalTitle = "Neues Menü anlegen";
            this.form.clear();
            this.form.reset();
            this.form.company_id = this.company.id;
            this.$bvModal.show("menuModal");
        },

        createMenu() {
            this.form
                .post("/menus")
                .then(() => {
                    this.$bvModal.hide("menuModal");
                    this.$swal({
                        icon: "success",
                        title: "Menü wurde erstellt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$parent.loadCompany();
                    this.$bvModal.show("codeModal");
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        saveMenu()
        {
            this.form
                .put("/menus/" + this.form.id)
                .then(() => {
                    this.$parent.loadCompany();
                    this.$swal({
                        icon: "success",
                                title: "Änderungen gespeichert!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                    });
                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                });
        },

        fillData(menu)
        {
            this.axios
                .get("/menuitems?menu_id=" + menu.id, {
                    params: {
                        sort_field: 'position',
                        sort_direction: 'asc'
                    }
                })
                .then((response) => {
                    this.isFilled = true; 
                    this.form.reset();
                    this.form.fill(menu); 
                    this.menu_items = response.data.data; 
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        }, 
    },
}
</script>

<style>

</style>