<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-4">
                        <div class="card card-primary card-outline">
                            <div class="card-header">
                                <div class="card-title">
                                    Einstellungen
                                </div>
                            </div>

                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="mr-1 form-group" style="display: inline-block;">
                                            <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                                <input type="checkbox" class="custom-control-input" id="customSwitchWishdate" v-model="form.always_set_wishdate">
                                                <label class="custom-control-label" for="customSwitchWishdate">Immer ein festes Lieferdatum setzen?</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="mr-1 form-group" style="display: inline-block;">
                                            <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                                <input type="checkbox" class="custom-control-input" id="customSwitchAbo" v-model="form.abo">
                                                <label class="custom-control-label" for="customSwitchAbo">Abofunktion erlauben?</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="mr-1 form-group" style="display: inline-block;">
                                            <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                                <input type="checkbox" class="custom-control-input" id="customSwitchShipping" v-model="form.fixed_shipping">
                                                <label class="custom-control-label" for="customSwitchShipping">Feste Liefertage?</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row" v-if="form.fixed_shipping == true">
                                    <div class="col-md-12">
                                        <div class="form-group row" v-for="(day,index) in form.deliverydays" :key="day.id">
                                           <label for="" class="control-label col-sm-3 col-form-label col-form-label-sm">
                                                <span v-if="index+1 == 0">Montag</span>
                                                <span v-if="index+1 == 1">Dienstag</span>
                                                <span v-if="index+1 == 2">Mittwoch</span>
                                                <span v-if="index+1 == 3">Donnerstag</span>
                                                <span v-if="index+1 == 4">Freitag</span>
                                                <span v-if="index+1 == 5">Samstag</span>
                                                <span v-if="index+1 == 6">Sonntag</span>
                                            </label> 
                                            <div class="col-sm-9">
                                                <input type="text" class="form-control form-control-sm" v-model="form.deliverydays[index]" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="prepaid_discount" class="control-label col-form-label col-form-label-sm">Vorkassenrabatt</label>
                                            <input type="number" class="form-control form-control-sm" name="purchase_price" id="prepaid_discount" :class="{'is-invalid': form.errors.has('prepaid_discount')}" v-model="form.prepaid_discount">
                                            <has-error :form="form" field="prepaid_discount"></has-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="mr-1 form-group" style="display: inline-block;">
                                            <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                                <input type="checkbox" class="custom-control-input" id="customSwitchOrderNotification" v-model="form.send_order_notification">
                                                <label class="custom-control-label" for="customSwitchOrderNotification">Bestellbenachrichtigung?</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row"  v-if="form.send_order_notification == 1 || form.send_order_notification == true">
                                    <label for="" class="control-label col-sm-2 col-form-label col-form-label-sm">Email:</label>
                                    <div class="col-sm-9">
                                        <input type="email" class="form-control form-control-sm" v-model="form.order_notification_mail" :class="{'is-invalid': form.errors.has('order_notification_mail')}"/>
                                        <has-error :form="form" field="order_notification_mail"></has-error>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-8">
                        <div class="card card-primary card-outline">
                            <div class="card-header">
                                <div class="card-title">
                                    Nutzung in der Front 
                                </div>
                            </div>

                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="mr-1 form-group" style="display: inline-block;">
                                            <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                                <input type="checkbox" class="custom-control-input" id="customSwitchLiefergebiet" v-model="form.useLiefergebiet">
                                                <label class="custom-control-label" for="customSwitchLiefergebiet">Liefergebiet anzeigen & vorgeben?</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="mr-1 form-group" style="display: inline-block;">
                                            <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                                <input type="checkbox" class="custom-control-input" id="customSwitchVerbesserung" v-model="form.useVerbesserung">
                                                <label class="custom-control-label" for="customSwitchVerbesserung">Verbesserungsvorschläge anzeigen?</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="mr-1 form-group" style="display: inline-block;">
                                            <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                                <input type="checkbox" class="custom-control-input" id="customSwitchUmfrage" v-model="form.useUmfrage">
                                                <label class="custom-control-label" for="customSwitchUmfrage">Umfrage anzeigen?</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="mr-1 form-group" style="display: inline-block;">
                                            <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                                <input type="checkbox" class="custom-control-input" id="customSwitchStores" v-model="form.useStores">
                                                <label class="custom-control-label" for="customSwitchStores">Filialen anzeigen?</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="mr-1 form-group" style="display: inline-block;">
                                            <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                                <input type="checkbox" class="custom-control-input" id="customSwitchHaendler" v-model="form.useHaendler">
                                                <label class="custom-control-label" for="customSwitchHaendler">Händlerübersicht anzeigen?</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="mr-1 form-group" style="display: inline-block;">
                                            <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                                <input type="checkbox" class="custom-control-input" id="customSwitchJobs" v-model="form.useJobs">
                                                <label class="custom-control-label" for="customSwitchJobs">Jobs anzeigen?</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="mr-1 form-group" style="display: inline-block;">
                                            <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                                <input type="checkbox" class="custom-control-input" id="customSwitchKatalog" v-model="form.useKatalog">
                                                <label class="custom-control-label" for="customSwitchKatalog">Katalog anzeigen?</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="mr-1 form-group" style="display: inline-block;">
                                            <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                                <input type="checkbox" class="custom-control-input" id="customSwitchKWK" v-model="form.useCustomerAdvertise">
                                                <label class="custom-control-label" for="customSwitchKWK">Kunden werben Kunden nutzen?</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card-footer">
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="form.useCustomerAdvertise">
                    <div class="col-md-12">
                        <div class="card card-primary card-outline">
                            <div class="card-header">
                                <div class="card-title">
                                    Kunden werben Kunden
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="card card-secondary card-outline">
                                            <div class="card-header">
                                                <div class="card-title">
                                                    Sharecode
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <div class="form-group">
                                                    <label>Typ</label>
                                                    <select class="form-control form-control-sm" v-model="form.shared_mode">
                                                        <option value="percent">Prozentual</option>
                                                        <option value="absolute">Absolut</option>
                                                        <option value="shippingfree">Versandfrei</option>
                                                    </select>
                                                </div>
                                                <div class="form-group" v-if="form.shared_mode != 'shippingfree'">
                                                    <label>Wert</label>
                                                    <input type="number" class="form-control form-control-sm" v-model="form.shared_amount">
                                                </div>
                                                <div class="form-group">
                                                    <label>Mindestbestellwert</label>
                                                    <input type="number" class="form-control form-control-sm" v-model="form.shared_min_amount">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="card card-secondary card-outline">
                                            <div class="card-header">
                                                <div class="card-title">
                                                    Kauf durch Sharecode
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <div class="form-group">
                                                    <label>Typ</label>
                                                    <select class="form-control form-control-sm" v-model="form.customer_mode">
                                                        <option value="percent">Prozentual</option>
                                                        <option value="absolute">Absolut</option>
                                                        <option value="shippingfree">Versandfrei</option>
                                                    </select>
                                                </div>
                                                <div class="form-group" v-if="form.customer_mode != 'shippingfree'">
                                                    <label>Wert</label>
                                                    <input type="number" class="form-control form-control-sm" v-model="form.customer_amount">
                                                </div>
                                                <div class="form-group">
                                                    <label>Mindestbestellwert</label>
                                                    <input type="number" class="form-control form-control-sm" v-model="form.customer_min_amount">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <button class="btn btn-primary btn-sm" @click="saveSettings">Speichern</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'companies.details.settings',

    props: [
        'company',
    ],

    watch:{
        company: function () {
            this.form.fill(this.company);
        },
        'form.send_order_notification': function() {
            if(this.form.send_order_notification == 0)
            {
                this.form.order_notification_mail = null;
            }
        },
        'form.useCustomerAdvertise': function() {
            if(this.form.useCustomerAdvertise == 0)
            {
                this.form.shared_amount = 0.0;
                this.form.shared_min_amount = 0.0;
                this.form.shared_shippingfree = 0;
    
                this.form.customer_amount = 0.0;
                this.form.customer_min_amount = 0.0;
                this.form.customer_shippingfree = 0;
    
                this.type_shared = 'percent';
                this.type_customer = 'percent';
            }
        },
        'form.shared_mode': function() {
            if(this.form.shared_mode == 'percent')
            {
                //
                this.form.shared_shippingfree = 0;
                this.form.shared_percent = 1;
            } else if(this.form.shared_mode == 'absolute')
            {
                //
                this.form.shared_shippingfree = 0;
                this.form.shared_percent = 0;
            } else if(this.form.shared_mode == 'shippingfree')
            {
                //
                this.form.shared_amount = 0.0;
                this.form.shared_shippingfree = 1;
                this.form.shared_percent = 0;
            }
        },
        'form.customer_mode': function() {
            if(this.form.customer_mode == 'percent')
            {
                //
                this.form.customer_shippingfree = 0;
                this.form.customer_percent = 1;
            } else if(this.form.customer_mode == 'absolute')
            {
                //
                this.form.customer_shippingfree = 0;
                this.form.customer_percent = 0;
            } else if(this.form.customer_mode == 'shippingfree')
            {
                //
                this.form.customer_amount = 0.0;
                this.form.customer_shippingfree = 1;
                this.form.customer_percent = 0;
            }
        }
    },

    data() {
        return {
            form: new window.Form({
                always_set_wishdate: '',
                abo: '', 
                fixed_shipping: '',
                useLiefergebiet: '',
                useVerbesserung: '',
                useUmfrage: '',
                useStores: '',
                useHaendler: '',
                useJobs: '',
                useKatalog: '',
                prepaid_discount: '',
                deliverydays: '',

                useCustomerAdvertise: 1,
                shared_amount:0.0,
                shared_percent: 1,
                shared_min_amount: 0.0,
                shared_shippingfree: 0,

                customer_amount: 0.0,
                customer_percent: 1,
                customer_min_amount: 0.0,
                customer_shippingfree: 0,

                shared_mode: 'percent',
                customer_mode: 'percent',

                send_order_notification: false,
            }),
        }
    },

    methods:{
        saveSettings()
        {
            this.form
                .put("/companies/" + this.$route.params.id)
                .then(() => {
                    this.$parent.loadCompany();
                    this.$swal({
                                icon: "success",
                                title: "Änderungen gespeichert!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });

                });
        },
    },

    created() {
        this.form.fill(this.company);
    },
}
</script>

<style>

</style>