<template>
    <b-container fluid>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-tabs">
                    <div class="p-0 pt-1 card-header">
                        <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                            <li class="nav-item" v-if="$auth.check('companies.display')">
                                <a class="nav-link active" id="custom-tabs-one-general-tab" data-toggle="pill" href="#custom-tabs-one-general" role="tab" aria-controls="custom-tabs-one-general" aria-selected="true">Allgemein</a>
                            </li>
                            <li class="nav-item" v-if="$auth.check('categories.display')">
                                <a class="nav-link" id="custom-tabs-one-categories-tab" data-toggle="pill" href="#custom-tabs-one-categories" role="tab" aria-controls="custom-tabs-one-categories" aria-selected="true">Kategorien</a>
                            </li>
                            <li class="nav-item" v-if="$auth.check('companies.display')">
                                <a class="nav-link" id="custom-tabs-one-settings-tab" data-toggle="pill" href="#custom-tabs-one-settings" role="tab" aria-controls="custom-tabs-one-settings" aria-selected="true">Shop-Einstellungen</a>
                            </li>
                            <li class="nav-item" v-if="$auth.check('companies.display')">
                                <a class="nav-link" id="custom-tabs-one-frontendsettings-tab" data-toggle="pill" href="#custom-tabs-one-frontendsettings" role="tab" aria-controls="custom-tabs-one-frontendsettings" aria-selected="true">Startseite</a>
                            </li>
                            <li class="nav-item" v-if="$auth.check('sliders.display')">
                                <a class="nav-link" id="custom-tabs-one-slider-tab" data-toggle="pill" href="#custom-tabs-one-slider" role="tab" aria-controls="custom-tabs-one-slider" aria-selected="true">Slider</a>
                            </li>
                            <li class="nav-item" v-if="$auth.check('pages.display')">
                                <a class="nav-link" id="custom-tabs-one-pages-tab" data-toggle="pill" href="#custom-tabs-one-pages" role="tab" aria-controls="custom-tabs-one-pages" aria-selected="true">Shop-Seiten</a>
                            </li>
                            <li class="nav-item" v-if="$auth.check('menus.display')">
                                <a class="nav-link" id="custom-tabs-one-menu-tab" data-toggle="pill" href="#custom-tabs-one-menu" role="tab" aria-controls="custom-tabs-one-menu" aria-selected="true">Menüs</a>
                            </li>
                            <li class="nav-item" v-if="$auth.check('dispatches.display') || $auth.check('payments.display')">
                                <a class="nav-link" id="custom-tabs-one-dispatches-payments-tab" data-toggle="pill" href="#custom-tabs-one-dispatches-payments" role="tab" aria-controls="custom-tabs-one-dispatches-payments" aria-selected="true">Versandarten & Zahlungsarten</a>
                            </li>
                            <li class="nav-item" v-if="$auth.check('icons.display')">
                                <a class="nav-link" id="custom-tabs-one-icons-tab" data-toggle="pill" href="#custom-tabs-one-icons" role="tab" aria-controls="custom-tabs-one-dispatches-payments" aria-selected="true">Icons</a>
                            </li>
                        </ul>
                    </div>
                    <div class="card-body">
                        <div class="tab-content" id="custom-tabs-one-tabContent">
                            <div class="tab-pane fade show active" id="custom-tabs-one-general" role="tabpanel" aria-labelledby="custom-tabs-one-general-tab">
                                <General :company="company" v-if="notEmptyObject(company)" />
                            </div>

                            <div class="tab-pane fade" id="custom-tabs-one-categories" role="tabpanel" aria-labelledby="custom-tabs-one-categories-tab">
                                <Categories :company="company" v-if="notEmptyObject(company)" />
                            </div>

                            <div class="tab-pane fade" id="custom-tabs-one-settings" role="tabpanel" aria-labelledby="custom-tabs-one-settings-tab">
                                <Settings :company="company" v-if="notEmptyObject(company)" />
                            </div>

                            <div class="tab-pane fade" id="custom-tabs-one-frontendsettings" role="tabpanel" aria-labelledby="custom-tabs-one-frontendsettings-tab">
                                <FrontendSettings :company="company" v-if="notEmptyObject(company)" />
                            </div>

                            <div class="tab-pane fade" id="custom-tabs-one-slider" role="tabpanel" aria-labelledby="custom-tabs-one-slider-tab">
                                <Slider :company="company" v-if="notEmptyObject(company)" />
                            </div>

                            <div class="tab-pane fade" id="custom-tabs-one-pages" role="tabpanel" aria-labelledby="custom-tabs-one-pages-tab">
                                <Pages :company="company" v-if="notEmptyObject(company)" />
                            </div>

                            <div class="tab-pane fade" id="custom-tabs-one-menu" role="tabpanel" aria-labelledby="custom-tabs-one-menu-tab">
                                <Menus :company="company" v-if="notEmptyObject(company)" />
                            </div>

                            <div class="tab-pane fade" id="custom-tabs-one-dispatches-payments" role="tabpanel" aria-labelledby="custom-tabs-one-dispatches-payments-tab">
                                <DispatchesPayments :company="company" v-if="notEmptyObject(company)" />
                            </div>

                            <div class="tab-pane fade" id="custom-tabs-one-icons" role="tabpanel" aria-labelledby="custom-tabs-one-icons-tab">
                                <Icons :company="company" v-if="notEmptyObject(company)" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>
import General from './components/General';
import Categories from './components/Categories';
import Settings from './components/Settings';
import FrontendSettings from './components/FrontendSettings';
import Slider from './components/Slider';
import Pages from './components/Pages';
import Menus from './components/Menus';
import DispatchesPayments from './components/DispatchesPayments';
import Icons from './components/Icons';

export default {
    name: 'companies.details',
    title: 'Firma bearbeiten',
    components:{
        General,
        Categories,
        Settings, 
        FrontendSettings, 
        Slider, 
        Pages, 
        Menus, 
        DispatchesPayments, 
        Icons,
    },

    data() {
        return{
            company: {},
        }
    },

    methods: {
        loadCompany(){
            this.$Progress.start();
            this.axios
                .get("/companies/" + this.$route.params.id)
                .then((response) => {
                    this.company = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        }
    },

    created() {
        this.loadCompany();
    }
}
</script>

<style>

</style>