<template>
    <div>
        <div class="row">
            <div class="col-md-3">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            Slider
                        </div>

                        <div class="card-tools">
                            <button type="button" class="btn btn-primary btn-sm" @click="createModal" v-if="$auth.check('sliders.create')">Neu</button>
                        </div>
                    </div>

                    <div class="card-body">
                        <table class="table table-sm table-hover">
                                <draggable tag="tbody" :list="sliders" class="" handle=".draggable" @change="saveOrder()">
                                    <tr v-for="slider in sliders" :key="slider.id" :class="slider.active == 0  ? 'table-danger' : 'table-success'">
                                        <td class="draggable text-center" style="vertical-align: middle;">
                                            <i class="fas fa-bars"></i>
                                        </td>
                                        <td @click="fillData(slider)" class="align-middle">
                                            <a href="#" style="color:#000">{{ slider.name }}</a>
                                        </td>
                                        <td class="align-middle">
                                            <button v-if="$auth.check('sliders.destroy')" class="align-middle float-right btn btn-danger btn-sm" @click="deleteSlider(slider)"><i class="fas fa-trash"></i></button>
                                        </td>
                                    </tr>
                                </draggable>
                        </table>
                    </div>
                </div>
            </div>

            <div class="col-md-9">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            Aktuell
                        </div>

                        <div class="card-tools">
                            <button class="btn btn-primary btn-sm" @click="saveSettings()" v-if="formEdit.id != '' && $auth.check('sliders.edit')">Speichern</button>
                        </div>
                    </div>
                    <div class="card-body">
                        <span v-if="isFilled == true">
                            <div class="row">
                                <div class="col-md-2">
                                    <div class="mr-1 form-group" style="display: inline-block;">
                                        <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                            <input type="checkbox" class="custom-control-input" id="customSwitchActiveSlider" v-model="sliderActive">
                                            <label class="custom-control-label" for="customSwitchActiveSlider">Aktiv</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <!-- <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="description" class="control-label">Position</label>
                                        <input type="text" class="form-control form-control-sm" v-model="formEdit.position" :class="{'is-invalid': formEdit.errors.has('position')}" />
                                        <has-error :form="formEdit" field="position"></has-error>
                                    </div>
                                </div> -->
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="" class="control-label">Name (Alt-Text)*</label>
                                        <input type="text" class="form-control form-control-sm" v-model="formEdit.name" :class="{'is-invalid': formEdit.errors.has('name')}" />
                                        <has-error :form="formEdit" field="name"></has-error>
                                    </div>  
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="description" class="control-label">Verlinkung</label>
                                        <input type="text" class="form-control form-control-sm" v-model="formEdit.link" :class="{'is-invalid': formEdit.errors.has('link')}" />
                                        <has-error :form="formEdit" field="link"></has-error>
                                    </div>
                                </div>

                                <div class="col-md-2">
                                    <div class="form-group" style="display: inline-block;margin-top: 30px;">
                                        <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                            <input type="checkbox" class="custom-control-input" id="customSwitchNewTabSlider" v-model="sliderNewTab">
                                            <label class="custom-control-label" for="customSwitchNewTabSlider">Im neuen Tab öffnen?</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="valid_at">Gültig ab:</label>           
                                        <input v-model="formEdit.valid_at" class="form-control form-control-sm" id="valid_at" type="date" name="valid_at" :class="{'is-invalid': formEdit.errors.has('valid_at')}">
                                        <has-error :form="formEdit" field="valid_at"></has-error>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="expires_at">Gültig bis:</label>           
                                        <input v-model="formEdit.expires_at" class="form-control form-control-sm" id="expires_at" type="date" name="expires_at" :class="{'is-invalid': formEdit.errors.has('expires_at')}">
                                        <has-error :form="formEdit" field="expires_at"></has-error>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="slider" class="control-label">Desktop Slider ändern?</label>
                                        <b-form-file id="slider" size="sm" class="form-control" v-model="formEdit.slider" :class="{'is-invalid': formEdit.errors.has('slider')}" />
                                        <has-error :form="formEdit" field="slider"></has-error>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="mobile_slider" class="control-label">Mobile Slider ändern?</label>
                                        <b-form-file id="mobile_slider" size="sm" class="form-control" v-model="formEdit.mobile_slider" :class="{'is-invalid': formEdit.errors.has('mobile_slider')}" />
                                        <has-error :form="formEdit" field="mobile_slider"></has-error>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <figure class="float-right mt-2" style="width: 100%; height: auto;">
                                        <img :src="imageUrl" id="Slider" class="img-fluid" alt="img">
                                    </figure>
                                </div>
                                <div class="col-md-6">
                                    <figure class="float-right mt-2" style="width: 100%; height: auto;">
                                        <img :src="mobile_imageUrl" id="Slider" class="img-fluid" alt="img">
                                    </figure>
                                </div>
                            </div>
                        </span>
                        <span v-if="isFilled == false">
                            <div class="row">
                                <div class="col-md-12">
                                    <h6> Kein Slider ausgewählt... </h6>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="sliderModal" v-bind:title="modalTitle" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk($event, form.id)">
            <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">
                <div class="row">
                    <div class="col-md-2">
                        <div class="mr-1 form-group" style="display: inline-block;">
                            <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                <input type="checkbox" class="custom-control-input" id="customSwitchActiveSlider" v-model="form.active">
                                <label class="custom-control-label" for="customSwitchActiveSlider">Aktiv</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-10">
                        <!-- <div class="form-group row">
                            <label for="description" class="control-label col-md-1 col-from-label">Position</label>
                            <div class="col-md-2">
                                <input type="text" class="form-control form-control-sm" v-model="form.position" :class="{'is-invalid': formEdit.errors.has('position')}"/>
                                <has-error :form="form" field="position"></has-error>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="" class="control-label">Name (Alt-Text)*</label>
                            <input type="text" class="form-control form-control-sm" v-model="form.name" :class="{'is-invalid': formEdit.errors.has('name')}"/>
                            <has-error :form="form" field="name"></has-error>
                        </div>  
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="description" class="control-label">Verlinkung</label>
                            <input type="text" class="form-control form-control-sm" v-model="form.link" :class="{'is-invalid': formEdit.errors.has('link')}"/>
                            <has-error :form="form" field="link"></has-error>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group" style="display: inline-block;margin-top: 30px;">
                            <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                <input type="checkbox" class="custom-control-input" id="customSwitchNewTabSlider" v-model="form.new_tab">
                                <label class="custom-control-label" for="customSwitchNewTabSlider">Im neuen Tab öffnen?</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="valid_at">Gültig ab:</label>           
                            <input v-model="form.valid_at" class="form-control form-control-sm" id="valid_at" type="date" name="valid_at">
                            <has-error :form="form" field="valid_at"></has-error>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="expires_at">Gültig bis:</label>           
                            <input v-model="form.expires_at" class="form-control form-control-sm" id="expires_at" type="date" name="expires_at">
                            <has-error :form="form" field="expires_at"></has-error>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="slider" class="control-label">Desktop Slider*</label>
                            <b-form-file id="slider" size="sm" class="form-control" v-model="form.slider" :class="{'is-invalid': form.errors.has('slider')}" />
                            <has-error :form="form" field="slider"></has-error>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="mobile_slider" class="control-label">Mobile Slider</label>
                            <b-form-file id="mobile_slider" size="sm" class="form-control" v-model="form.mobile_slider" :class="{'is-invalid': form.errors.has('mobile_slider')}" />
                            <has-error :form="form" field="mobile_slider"></has-error>
                        </div>
                    </div>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
import draggable from 'vuedraggable'

export default {
    name: 'companies.details.slider',
    components: {
        draggable
    },
    props: [
        'company',
    ],

    data() {
        return {
            sliders: [],
            form: new window.UploadForm({
                id: "",
                company_id: '', 
                name: '', 
                link: '', 
                active: 1, 
                position: 1, 
                slider: [],
                mobile_slider: [],
                valid_at: '', 
                expires_at: '', 
                new_tab: 0, 
            }),
            formEdit: new window.UploadForm({
                id: "",
                company_id: '', 
                name: '', 
                link: '', 
                active: 1, 
                position: '', 
                slider: [],
                mobile_slider: [],
                _method: "PUT",
                valid_at: '', 
                expires_at: '', 
                new_tab: 0, 
            }),
            editMode: false,
            modalTitle: '',
            imageUrl: '', 
            mobile_imageUrl: '', 
            isFilled: false, 
            sliderActive: false,
            sliderNewTab: false,
        }
    },

    methods: {
        saveOrder()
        {
            this.axios
                .post('/slider/changeOrder', {
                    sliders: this.sliders
                })
                .then(() => {
                    this.getSlider();
                    this.isFilled = false;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Beim ändern der reihenfolge ist etwas schief gelaufen.'
                    })
                })
        },
        deleteSlider(slider){
            this.$swal({
                    title: "Möchtest du den Slider wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Ja, LÖSCHEN!!!`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.axios
                            .delete("/sliders/" + slider.id)
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Liste gelöscht!",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                });
                                this.formEdit.clear();
                                this.formEdit.reset();
                                this.imageUrl = '';
                                this.isFilled = false;
                                this.getSlider();
                            })
                            .catch((error) => {
                                var message = 'Da ist etwas schief gelaufen'; 
                                if('error' in error.response.data)
                                {
                                    message = error.response.data.error; 
                                }
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: message, 
                                });
                            });
                    }
                });
        },

        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.handleSubmit()
        },

        handleSubmit() {
            this.createSlider();
        },

        createModal() {
            this.isFilled = false; 
            this.editMode = false;
            this.modalTitle = "Neuen Slider anlegen";
            this.form.clear();
            this.form.reset();
            this.form.company_id = this.company.id;
            this.$bvModal.show("sliderModal");
        },
        getSlider() {
            this.axios
                .get('sliders', {
                    params: {
                        company_id: this.company.id
                    }
                })
                .then((response) => {
                    this.sliders = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Beim laden der Slider ist etwas schief gelaufen'
                    })
                });
        },

        createSlider() {
            this.form
                .post("/sliders")
                .then(() => {
                    this.$bvModal.hide("sliderModal");
                    this.$swal({
                        icon: "success",
                        title: "Der Slider wurde erstellt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.getSlider();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        saveSettings()
        {
            this.formEdit._method = 'PUT'; 
            if(this.sliderActive == true)
            {
                this.formEdit.active = 1;
            }
            else 
            {
                this.formEdit.active = 0;
            }
            if(this.sliderNewTab == true)
            {
                this.formEdit.new_tab = 1;
            }
            else 
            {
                this.formEdit.new_tab = 0;
            }

            

            this.formEdit
                .post("/sliders/" + this.formEdit.id)
                .then((response) => {
                    this.getSlider();
                    this.$swal({
                        icon: "success",
                                title: "Änderungen gespeichert!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                    });
                    // this.imageUrl = response.data.data.imageUrl;
                    this.fillData(response.data.data);
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                });
        },

        fillData(slider)
        {
            this.isFilled = true; 
            this.formEdit.reset();
            this.formEdit.fill(slider); 
            this.imageUrl = slider.imageUrl;
            this.mobile_imageUrl = slider.mobile_imageUrl;
            if(slider.active == 1)
            {
                this.sliderActive = true;
            }
            if(slider.active == 0)
            {
                this.sliderActive = false; 
            }
            if(slider.new_tab == 1)
            {
                this.sliderNewTab = true;
            }
            if(slider.new_tab == 0)
            {
                this.sliderNewTab = false; 
            }

        },
    },
    mounted() {
        this.getSlider();
    }
}
</script>

<style>

</style>