<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="" class="control-label col-sm-2 col-form-label col-form-label-sm">Name</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" v-model="form.name" />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="" class="control-label col-sm-2 col-form-label col-form-label-sm">Kundengruppe Privatkunde</label>
                            <div class="col-sm-9">
                                <select class="form-control form-control-sm" v-model="form.default_customer_group_private">
                                    <option v-for="group in groups" :key="group.id" :value="group.id">{{ group.description }}</option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="" class="control-label col-sm-2 col-form-label col-form-label-sm">Prefix</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" v-model="form.prefix" />
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-sm-11">
                                <h5>Firmensitz</h5>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="" class="control-label col-sm-2 col-form-label col-form-label-sm">Straße</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" v-model="form.street" />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="" class="control-label col-sm-2 col-form-label col-form-label-sm">PLZ</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" v-model="form.zipcode" />
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-sm-11">
                                <h5>Lieferanschrift</h5>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="" class="control-label col-sm-2 col-form-label col-form-label-sm">Straße</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" v-model="form.sender_street"  :class="{'is-invalid': form.errors.has('sender_street')}"/>
                                <has-error :form="form" field="sender_street"></has-error>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="" class="control-label col-sm-2 col-form-label col-form-label-sm">PLZ</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" v-model="form.sender_zipcode"  :class="{'is-invalid': form.errors.has('sender_zipcode')}"/>
                                <has-error :form="form" field="sender_zipcode"></has-error>
                            </div>
                        </div>

                        
                        <div class="row">
                            <div class="col-sm-11">
                                <h5>&nbsp;</h5>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="" class="control-label col-sm-2 col-form-label col-form-label-sm">Fimierung</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" v-model="form.name_conform" />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="" class="control-label col-sm-2 col-form-label col-form-label-sm">Domain Alias</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" v-model="form.domain_alias" />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="" class="control-label col-sm-2 col-form-label col-form-label-sm">Telefon</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" v-model="form.phone" />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="" class="control-label col-sm-2 col-form-label col-form-label-sm">Nummer Rechnung</label>
                            <div class="col-sm-9">
                                <input type="text" disabled class="form-control form-control-sm" v-model="form.invoice_number" />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="" class="control-label col-sm-2 col-form-label col-form-label-sm">IBAN</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" v-model="form.iban" />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="" class="control-label col-sm-2 col-form-label col-form-label-sm">Meta-Description</label>
                            <div class="col-sm-9">
                                <textarea class="form-control form-control-sm" v-model="form.meta_description" rows="5">
                                </textarea>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group row">
                            <label for="" class="control-label col-sm-2 col-form-label col-form-label-sm">Domain</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" v-model="form.domain" />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="" class="control-label col-sm-2 col-form-label col-form-label-sm">Kundengruppe Firmenkunde</label>
                            <div class="col-sm-9">
                                <select class="form-control form-control-sm" v-model="form.default_customer_group_business">
                                    <option v-for="group in groups" :key="group.id" :value="group.id">{{ group.description }}</option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="" class="control-label col-sm-2 col-form-label col-form-label-sm">Theme</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" v-model="form.theme" />
                            </div>
                        </div>

                        
                        <div class="row">
                            <div class="col-sm-11">
                                <h5>&nbsp;</h5>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="" class="control-label col-sm-2 col-form-label col-form-label-sm">Haus-Nr.</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" v-model="form.house_number" />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="" class="control-label col-sm-2 col-form-label col-form-label-sm">Stadt</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" v-model="form.city" />
                            </div>
                        </div>

                        
                        <div class="row">
                            <div class="col-sm-11">
                                <h5>&nbsp;</h5>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="" class="control-label col-sm-2 col-form-label col-form-label-sm">Haus-Nr.</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" v-model="form.sender_house_number" :class="{'is-invalid': form.errors.has('sender_house_number')}"/>
                                <has-error :form="form" field="sender_house_number"></has-error>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="" class="control-label col-sm-2 col-form-label col-form-label-sm">Stadt</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" v-model="form.sender_city" :class="{'is-invalid': form.errors.has('sender_city')}"/>
                                <has-error :form="form" field="sender_city"></has-error>
                            </div>
                        </div>

                        
                        <div class="row">
                            <div class="col-sm-11">
                                <h5>&nbsp;</h5>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="" class="control-label col-sm-2 col-form-label col-form-label-sm">E-Mail</label>
                            <div class="col-sm-9">
                                <input type="email" class="form-control form-control-sm" v-model="form.email" />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="" class="control-label col-sm-2 col-form-label col-form-label-sm">Admin E-Mail</label>
                            <div class="col-sm-9">
                                <input type="email" class="form-control form-control-sm" v-model="form.order_copy_mail" />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="" class="control-label col-sm-2 col-form-label col-form-label-sm">Whats App</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" v-model="form.whatsapp" />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="" class="control-label col-sm-2 col-form-label col-form-label-sm">Nummer Picklist</label>
                            <div class="col-sm-9">
                                <input type="text" disabled class="form-control form-control-sm" v-model="form.picklist_number" />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="" class="control-label col-sm-2 col-form-label col-form-label-sm">Meta-Keywords</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" v-model="form.meta_keywords" />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="" class="control-label col-sm-2 col-form-label col-form-label-sm">Steuerberechnung</label>
                            <div class="col-sm-9">
                                <select class="form-control form-control-sm" v-model="form.dispatch_tax_rate">
                                    <option value="calculate">Kalkulieren nach MwSt.-Satz</option>
                                    <option value="7">7 %</option>
                                    <option value="19">19 %</option>
                                </select>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="row">
                    <div class="text-right col-md-12">
                        <button class="btn btn-primary btn-sm" @click="saveGeneral">Speichern</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'companies.details.general',

    props: [
        'company',
    ],

    watch:{
        company: function () {
            this.form.fill(this.company);
        }
    },

    data() {
        return {
            groups: [],
            form: new window.Form({
                name: "",
                domain: "",
                default_customer_group_private: "",
                default_customer_group_business: "",
                prefix: "",
                theme: "",
                phone: "",
                whatsapp: "",
                email: "",
                street: "",
                house_number: "",
                zipcode: "",
                city: "",
                name_conform: "",
                invoice_number: "",
                picklist_number: "",
                iban: "",
                order_copy_mail: "",
                meta_keywords: "",
                meta_description: "",
                domain_alias: "",
                dispatch_tax_rate: "", 
                sender_street: null,
                sender_house_number: null,
                sender_zipcode: null,
                sender_city: null,
            }),
        }
    },

    methods:{

        saveGeneral()
        {
            this.$Progress.start();
            this.form
                .put("/companies/" + this.$route.params.id)
                .then(() => {
                    this.$parent.loadCompany();
                    this.$swal({
                                icon: "success",
                                title: "Änderungen gespeichert!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                    this.$Progress.finish();
                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                    this.$Progress.fail();
                });
        },

        loadGroups(){
            this.$Progress.start();
            this.axios
                .get("/customer-groups", {
                    params: {
                        company: this.$route.params.id
                    }
                })
                .then((response) => {
                    this.groups = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        }
    },

    created() {
        this.loadGroups();
        this.form.fill(this.company);
    },
}
</script>

<style>

</style>