<template>
    <div>
        <div class="row">
            <div class="col-md-3">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title">
                            Kategoriebaum
                        </div>

                        <div class="card-tools">
                            <button type="button" class="btn btn-primary btn-sm mr-1" @click="getCategories()">
                                <i class="fas fa-sync"></i>
                            </button>
                            <button type="button" class="btn btn-primary btn-sm" @click="createModal" v-if="$auth.check('categories.edit')">Neu</button>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="card-tools text-center">
                            <div class="card-tools">
                                <input class="form-control form-control-sm" v-model="search" placeholder="Kategorie suchen"  />
                            </div>
                        </div>
                        <Tree :value="categories" @change="saveOrder" foldAllAfterMounted>
                            <!-- eslint-disable-next-line -->
                            <span slot-scope="{node, index, path, tree}">
                                <i class="fas fa-folder-plus" @click="tree.toggleFold(node, path)" v-if="node.$folded"></i>
                                <i class="fas fa-folder-minus" @click="tree.toggleFold(node, path)" v-if="!node.$folded"></i>
                                <a class="ml-1" href="#" @click="fillData(node.id)">{{node.name}}</a>
                            </span>
                        </Tree>
                    </div>

                    <div class="card-footer">

                    </div>
                </div>
            </div>

            <div class="col-md-9">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title">
                            Kategorieeinstellungen
                        </div>
                        <div class="card-tools">
                            <button class="btn btn-danger btn-sm mr-1" @click="destoryCategory()" v-if="form.id != '' && $auth.check('categories.destroy')" >Löschen</button>
                            <button class="btn btn-primary btn-sm" @click="saveSettings()" v-if="form.id != '' && $auth.check('categories.edit')" >Speichern</button>
                        </div>
                    </div>

                    <div class="card-body">
                        <span v-if="isFilled == true">

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                            <input type="checkbox" class="custom-control-input" id="customSwitch3" v-model="form.active">
                                            <label class="custom-control-label" for="customSwitch3">Aktiv</label>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="" class="control-label">Name*</label>
                                        <input type="text" class="form-control form-control-sm" v-model="form.name" :class="{'is-invalid': form.errors.has('name')}"/>
                                        <has-error :form="form" field="name"></has-error>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="" class="control-label">Titel</label>
                                        <input type="text" class="form-control form-control-sm" v-model="form.title" />
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="" class="control-label">Kurzbeschreibung</label>
                                        <input type="text" class="form-control form-control-sm" v-model="form.short_description" />
                                    </div>
                                </div>
                                <div class="col-md-6">

                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="" class="control-label">Icon*</label> 
                                        <div class="row">
                                            <div class="col-xs-2">
                                                <span v-html="iconVector()" class="hls-icons"></span>
                                            </div>
                                            <div class="col-md">
                                                <v-select :options="icons" v-model="form.icon_id" :reduce="icon => icon.id" label="name" :class="{'is-invalid': form.errors.has('icon_id')}">
                                                    <template #search="{attributes, events}">
                                                        <input
                                                        class="vs__search"
                                                        :required="!form.icon_id"
                                                        v-bind="attributes"
                                                        v-on="events"
                                                        />
                                                    </template>
                                                </v-select>
                                                <has-error :form="form" field="icon_id"></has-error>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="" class="control-label">Farbe*</label>
                                        <select class="form-control form-control-sm" v-model="form.color" :class="{'is-invalid': form.errors.has('color')}">
                                            <option :value="null">-- Bitte wählen --</option>
                                            <option value="white">Weiß</option>
                                            <option value="red">Rot</option>
                                            <option value="yellow">Gelb</option>
                                            <option value="green">Grün</option>
                                            <option value="anthrazit">Anthrazit</option>
                                        </select>
                                        <has-error :form="form" field="color"></has-error>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Funktionalität</label>
                                        <select class="form-control form-control-sm" v-model="form.stream_id" :class="{'is-invalid': form.errors.has('stream_id')}">
                                            <option :value="null">-- Keine Funktionalität --</option>
                                            <option v-for="stream in productStreams" :key="stream.id" :value="stream.id">{{ stream.description }}</option>
                                        </select>
                                        <has-error :form="form" field="stream_id"></has-error>
                                    </div>
                                </div>
                                <div class="col-md-6">

                                </div>
                                <div class="col-md-12">
                                    <hr>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="" class="control-label">Meta-Titel</label>
                                        <input type="text" class="form-control form-control-sm" v-model="form.meta_title" />
                                        <has-error :form="form" field="meta_title"></has-error>
                                        <br>
                                        <label for="" class="control-label">Meta-Keywords</label>
                                        <input type="text" class="form-control form-control-sm" v-model="form.meta_keywords" />
                                        <has-error :form="form" field="meta_keywords"></has-error>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="" class="control-label">Meta-Description</label>
                                        <textarea class="form-control form-control-sm" v-model="form.meta_description" rows="5"></textarea>
                                        <has-error :form="form" field="meta_description"></has-error>
                                    </div>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group row">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="" class="control-label col-sm-2 col-form-label col-form-label-sm">Beschreibung (CMS)</label>
                                        <ckeditor :editor="editor" :config="editorConfig" v-model="form.description" :class="{'is-invalid': form.errors.has('description')}"></ckeditor>
                                        <has-error :form="form" field="description"></has-error>
                                    </div>
                                </div>
                            </div>
                        </span>
                        <span v-if="isFilled == false">
                            <div class="row">
                                <div class="col-md-12">
                                    <h6> Keine Kategorie ausgewählt... </h6>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="categoryModal" v-bind:title="modalTitle" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk($event, form.id)">
            <form ref="form" @submit.stop.prevent="handleSubmit">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <div class="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                <input type="checkbox" class="custom-control-input" id="customSwitch3" v-model="form.active">
                                <label class="custom-control-label" for="customSwitch3">Aktiv</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="" class="control-label">Name*</label>
                            <input type="text" class="form-control form-control-sm" v-model="form.name" :class="{'is-invalid': form.errors.has('name')}"/>
                            <has-error :form="form" field="name"></has-error>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="" class="control-label">Titel</label>
                            <input type="text" class="form-control form-control-sm" v-model="form.title" />
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="" class="control-label">Kurzbeschreibung</label>
                            <input type="text" class="form-control form-control-sm" v-model="form.short_description" />
                        </div>
                    </div>
                    <div class="col-md-6">

                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="" class="control-label">Icon*</label> 
                            <div class="row">
                                <div class="col-xs-2">
                                    <span v-html="iconVector()" class="hls-icons"></span>
                                </div>
                                <div class="col-md">
                                    <v-select :options="icons" v-model="form.icon_id" :reduce="icon => icon.id" label="name" :class="{'is-invalid': form.errors.has('icon_id')}">
                                        <template #search="{attributes, events}">
                                            <input
                                            class="vs__search"
                                            :required="!form.icon_id"
                                            v-bind="attributes"
                                            v-on="events"
                                            />
                                        </template>
                                    </v-select>
                                    <has-error :form="form" field="icon_id"></has-error>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="" class="control-label">Farbe*</label>
                            <select class="form-control form-control-sm" v-model="form.color" :class="{'is-invalid': form.errors.has('color')}">
                                <option :value="null">-- Bitte wählen --</option>
                                <option value="white">Weiß</option>
                                <option value="red">Rot</option>
                                <option value="yellow">Gelb</option>
                                <option value="green">Grün</option>
                                <option value="anthrazit">Anthrazit</option>
                            </select>
                            <has-error :form="form" field="color"></has-error>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Funktionalität</label>
                            <select class="form-control form-control-sm" v-model="form.stream_id" :class="{'is-invalid': form.errors.has('stream_id')}">
                                <option :value="null">-- Keine Funktionalität --</option>
                                <option v-for="stream in productStreams" :key="stream.id" :value="stream.id">{{ stream.description }}</option>
                            </select>
                            <has-error :form="form" field="stream_id"></has-error>
                        </div>
                    </div>
                    <div class="col-md-6">

                    </div>
                    <div class="col-md-12">
                        <hr>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="" class="control-label">Meta-Titel</label>
                            <input type="text" class="form-control form-control-sm" v-model="form.meta_title" />
                            <has-error :form="form" field="meta_title"></has-error>
                        </div>
                        <div class="form-group">
                            <label for="" class="control-label">Meta-Description</label>
                            <textarea class="form-control form-control-sm" v-model="form.meta_description" rows="5"></textarea>
                            <has-error :form="form" field="meta_description"></has-error>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label for="" class="control-label">Meta-Keywords</label>
                            <input type="text" class="form-control form-control-sm" v-model="form.meta_keywords" />
                            <has-error :form="form" field="meta_keywords"></has-error>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="" class="control-label">Beschreibung (CMS)</label>
                            <ckeditor :editor="editor" :config="editorConfig" v-model="form.description" :class="{'is-invalid': form.errors.has('description')}"></ckeditor>
                            <has-error :form="form" field="description"></has-error>
                        </div>
                    </div>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
import 'he-tree-vue/dist/he-tree-vue.css'
import {Tree, Fold, foldAll, Draggable, unfoldAll, Check} from 'he-tree-vue'
import { walkTreeData } from "he-tree-vue";
import ClassicEditor from './../../../assets/packages/ckeditor5/src/ckeditor';


export default {
    name: 'companies.details.categories',

    props: [
        'company',
    ],

    components: {Tree: Tree.mixPlugins([Fold, Draggable, Check])},
    data() {
        return {
            search: '',
            icons: [],
            categories: [],
            productStreams: [],
            editor: ClassicEditor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }
            },
            form: new window.Form({
                id: "",
                name: "",
                title: "",
                description: null,
                meta_title: "",
                meta_description: "",
                meta_keywords: "",
                icon: "",
                color: null,
                editSlug: null,
                icon_id: null,
                active: 1,
                stream_id: null,
                short_description: null,
            }),
            modalTitle: '', 
            isFilled: false,
        }
    },

    watch:{
        search: function () {
            this.searchCategories();
        }
    },

    methods: {
        collapseAll() {
            foldAll(this.categories);
        },
        uncollapseAll() {
            unfoldAll(this.categories);
        },
        iconVector() {
            const icon = this.icons.findIndex(element => element.id == this.form.icon_id);
            if(icon == -1)
            {
                return null;
            }

            return this.icons[icon].vector;
        },
        getIcons() {
            this.axios
                .get('/icons', {
                    params: {
                        company_id: this.company.id
                    }
                })
                .then((response) => {
                    this.icons = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Beim laden der Icons ist etwas schief gelaufen'
                    });
                })
        },
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.handleSubmit()
        },

        handleSubmit() {
            this.createCategory();
        },

        createModal() {
            this.isFilled = false; 
            this.editMode = false;
            this.modalTitle = "Neue Seite anlegen";
            this.form.clear();
            this.form.reset();
            this.form.company_id = this.company.id;
            this.$bvModal.show("categoryModal");
            this.getIcons();
        },

        createCategory() {
            this.form
                .post("/categories")
                .then(() => {
                    this.$bvModal.hide("categoryModal");
                    this.$swal({
                        icon: "success",
                        title: "Die Seite wurde erstellt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.getCategories();
                })
                .catch(() => {

                });
        },

        saveSettings()
        {
            this.form
                .put("/categories/" + this.form.id)
                .then(() => {
                    this.getCategories();
                    this.$swal({
                        icon: "success",
                                title: "Änderungen gespeichert!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                    });
                    this.fillData(this.form.id);
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$Progress.fail();
                });
        },
        
        fillData(id)
        {
            this.axios
                .get("/categories/" + id)
                .then((response) => {
                    this.isFilled = true; 
                    this.form.reset();
                    console.log(response.data.data);
                    this.form.fill(response.data.data)
                    this.$Progress.finish();
                    this.form.editSlug = false;
                    this.getIcons();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        saveOrder() {
            this.$swal({
                    title: "Möchtest du die Reihenfolge der Kategorien wirklich ändern?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Ja`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        this.axios
                            .post("/categories/saveOrder", {data: this.categories} )
                            .then(() => {
                                this.getCategories();
                                this.$Progress.finish();
                                this.$swal({
                                    icon: "success",
                                    title: "Reihenfolge geändert",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                });
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                                this.$Progress.fail();
                            });
                    }
                    if (!result.isConfirmed) {
                        //console.log("Press Cance");
                        this.getCategories();
                    }
                });
        },
        getCategories()
        {
            this.axios
                .get('/categories', {
                    params: {
                        company_id: this.$parent.company.id
                    }
                })
                .then((response) => {
                    this.categories = response.data.data;
                    this.collapseAll();
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Fehler beim laden der Kategorien'
                    });
                })
        },
        getProductStreams() {
            this.axios
                .get('/product-streams')
                .then((response) => {
                    this.productStreams = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    });
                })
        },
        destoryCategory() {
            this.$swal({
                    title: "Möchtest du die Kategorie wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Ja, LÖSCHEN!!!`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        this.axios.delete('/categories/' + this.form.id)
                            .then(() => {
                                this.getCategories();
                                this.form.clear();
                                this.$swal({
                                    icon: "success",
                                            title: "Kategorie wurde gelöscht!",
                                            toast: true,
                                            position: 'top-end',
                                            showConfirmButton: false,
                                            timer: 2000,
                                            timerProgressBar: true,
                                });
                            })
                            .catch((response) => {
                                this.$swal({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: response.response.data.error
                                })
                            })
                    }
                });
        },
        searchCategories() {
            walkTreeData(this.categories, (node) => {
                this.$set(node, '$hidden', !node.name.includes(this.search))
            });
        },
    },
    mounted() {
        this.getCategories();
        this.getProductStreams();
    },
    created() {
    },
}
</script>

<style>
.hls-icons svg {
    max-width: 32px;
    max-height: 32px;
}
.is-invalid {
    --vs-border-color: #dc3545;
}
</style>